import React, { Component } from "react"
import { connect } from "react-redux"

import DashboardBanner from "./DashboardBanner"
import SectionHead from "../includes/SectionHead"
import ApplicationItem from "./application-item/ApplicationItem"
import {
    getCourses,
    getDepartments,
    fetchMyApplications,
} from "../../../actions"

import "./dashboard.css"
import { staticURI } from "../includes/jsFunctions"

class Dashboard extends Component {
    componentDidMount() {
        this.props.getCourses()
        this.props.getDepartments()
        this.props.fetchMyApplications()
    }

    render() {
        const { loginLoading, login, applications, user } = this.props.user

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        const applied_courses =
            applications && applications.length > 0
                ? applications.map((application) => {
                      return (
                          <ApplicationItem
                              title={
                                  application.course && application.course.title
                                      ? application.course.title
                                      : "Not Found"
                              }
                              step={application.steps}
                              status={application.status}
                              id={application.application_id}
                              key={application.id}
                              transaction_id={
                                  application.transaction &&
                                  application.transaction.transaction_id
                                      ? application.transaction.transaction_id
                                      : null
                              }
                              last_date={
                                  application.course &&
                                  application.course.last_date_js
                                      ? application.course.last_date_js
                                      : null
                              }
                              admit={
                                  application.is_admit_generated &&
                                  application.admit_card
                                      ? application.admit_card.roll_no
                                      : null
                              }
                              course_type={
                                  application.course &&
                                  application.course.category
                                      ? application.course.category
                                      : null
                              }
                              appeared_exam={
                                  application.appeared_exam ? true : false
                              }
                              admission_fee={application.admission_payment}
                              admission_notified={
                                  application.course &&
                                  application.course.category === "E"
                                      ? application.is_entrance_app_shortlisted_for_admission
                                      : application.is_notified_admission
                              }
                              admission_transaction_id={
                                  application.addmission_payment_details
                                      ? application.addmission_payment_details
                                            .transaction_id
                                      : null
                              }
                              email={user ? user.email : null}
                              is_shortlisted_admission={
                                  application.course &&
                                  application.course.category === "E"
                                      ? application.is_entrance_app_shortlisted_for_admission
                                      : application.is_shortlisted_admission
                              }
                          />
                      )
                  })
                : null

        return (
            <React.Fragment>
                <DashboardBanner />
                <section
                    className="applied-courses-section"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <div className="container mx-auto px-4 py-16">
                        <SectionHead title="My Applications" />
                        {/* Online application will start very soon, please keep visiting the portal. */}
                        <div className="applications-list">
                            {applied_courses}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps, {
    getCourses,
    getDepartments,
    fetchMyApplications,
})(Dashboard)
