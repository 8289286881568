import React, { Component } from "react"
import { FiChevronRight, FiMail } from "react-icons/fi"
import { FaWhatsapp } from "react-icons/fa"

import { scrollToTop } from "../includes/jsFunctions"
import PageBanner from "../includes/page-banner/PageBanner"

class ContactUs extends Component {
    componentDidMount() {
        scrollToTop()
    }

    render() {
        return (
            <React.Fragment>
                <PageBanner title="Contact Us" image="/images/page.jpg" />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li>
                                    <FiChevronRight />
                                </li>
                                <li>Contact Us</li>
                            </ul>
                        </div>

                        <h1 className="text-center font-bold text-lg md:text-xl">
                            CONTACT US (WHATSAPP ONLY)
                        </h1>

                        <div className="grid grid-cols-2 mt-3 mb-4">
                            <a
                                href="https://wa.me/917627927079"
                                className="bg-white p-4 shadow-md mb-4 mx-2 text-center flex justify-center"
                            >
                                <span className="mt-1">
                                    <FaWhatsapp />
                                </span>
                                &emsp;7627927079
                            </a>
                            <a
                                href="https://wa.me/919362225963"
                                className="bg-white p-4 shadow-md mb-4 mx-2 text-center flex justify-center"
                            >
                                <span className="mt-1">
                                    <FaWhatsapp />
                                </span>
                                &emsp;9362225963
                            </a>
                        </div>

                        {/* <hr/>

                        <h1 className="text-center font-bold text-lg md:text-xl mt-4">EMAIL US</h1>

                        <div className="flex justify-center mt-3">
                            <a href="mailto:admission2022@rgu.ac.in" className="bg-white p-4 shadow-md mb-4 mx-2 text-center flex justify-center">
                                <span className="mt-1"><FiMail /></span>&emsp;admission2022@rgu.ac.in
                            </a>
                        </div> */}
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default ContactUs
