import React, { Component } from 'react';
import { FiChevronRight } from "react-icons/fi";

import { scrollToTop } from '../includes/jsFunctions';
import PageBanner from '../includes/page-banner/PageBanner';

class PrivacyPolicy extends Component {

    componentDidMount(){
        scrollToTop();
    }

    render() {
        return (
            <React.Fragment>
                <PageBanner title="Privacy Policy" image="/images/page.jpg" />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li><FiChevronRight /></li>
                                <li>Privacy Policy</li>
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow-md mb-4">
                            
                            <h1 className="text-center font-bold text-lg md:text-xl">PRIVACY POLICY</h1>

                            <div className="mt-3">
                                <p>
                                    We at Rajiv Gandhi University value your privacy and understand how important it is for you. To that effect, we have the following privacy policy in place with regard to the collection and use of personal information submitted to this online portal.
                                </p>
                                <h3 className="font-bold text-lg mt-3">Information Collection and Use</h3>
                                <p>
                                    The information collected by Rajiv Gandhi University will be used strictly for the purpose stated at the time of collection. Such information will not be sold, traded, rented or in any way transferred to a third party, including direct marketing companies and similar organizations for commercial purposes. However, Rajiv Gandhi University may reveal the collected information to its selected employees, agents, consultants and others who the Rajiv Gandhi University believes should know. The Institute may also divulge the information to law enforcement authorities, courts and such others if need arises.
                                </p>
                                <h3 className="font-bold text-lg mt-3">Cookies</h3>
                                <p>
                                The portal may use cookies to track usage and manage services on the website. Cookies are small bits of data that may be transferred to the user's hard drive when they log in or access a particular part of the website. These are used for a variety of purposes including authentication and can be blocked by reconfiguring the appropriate settings in user's web browser.
                                </p>
                                <h3 className="font-bold text-lg mt-3">Security</h3>
                                <p>
                                We, at Rajiv Gandhi University takes every possible precaution to ensure that your data is safe and secure barring circumstances beyond our control. Unfortunately, we cannot guarantee control over the medium of transmission i.e., internet. Though you have to supply the necessary information at your own risk, Rajiv Gandhi University will always try to ensure the security and safety of the same. Once we receive the data, we will strive to keep it secure using the latest technology, constant upgrades to our digital security infrastructure and restricted access. All information you provide us will be stored in Rajiv Gandhi University secured servers.
                                </p>
                            </div>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default PrivacyPolicy;
