export const openTab = (tabName, btnName, wrapper, tabsWrap) => {
    const container = !wrapper ? `tabcontent` : `${wrapper}`
    const tabsLink = !tabsWrap ? `tablinks` : `${tabsWrap}`

    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName(container)
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none"
    }
    tablinks = document.getElementsByClassName(tabsLink)
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "")
    }
    document.querySelector(`#${tabName}`).style.display = "block"
    document.querySelector(`#${btnName}`).className += " active"
}

export const redirect = (step, id) => {
    if (step === "1") {
        window.location.href = `${staticURI}/apply/personal-details/${id}`
    } else if (step === "2") {
        window.location.href = `${staticURI}/apply/basic-details/${id}`
    } else if (step === "3") {
        window.location.href = `${staticURI}/apply/education-details/${id}`
    } else if (step === "4") {
        window.location.href = `${staticURI}/apply/documents-upload/${id}`
    } else if (step === "5") {
        window.location.href = `${staticURI}/apply/declaration/${id}`
    }
}

export const makePayment = (id) => {
    window.location.href = `${staticURI}/apply/make-payment/${id}`
}

export const viewAdmissionReceipt = (id) => {
    window.location.href = `${staticURI}/admission-payment-receipt/${id}`
}

export const makeAdmissionPayment = (id) => {
    window.location.href = `${staticURI}/make-admission-payment/${id}`
}

export const viewApplication = (id) => {
    window.location.href = `${staticURI}/view-application/${id}`
}

export const viewPaymentReceipt = (id) => {
    window.location.href = `${staticURI}/payment-receipt/${id}`
}

export const viewAdmit = (id) => {
    window.location.href = `${staticURI}/admit-card/${id}`
}

export const baseURL = "https://admissions.rgu.ac.in/admin/uploads"
export const baseURL2 = "https://admissions.rgu.ac.in/admin"

// export const baseURL = "https://admissions.rgu.ac.in/test/admin/uploads"
// export const baseURL2 = "https://admissions.rgu.ac.in/test/admin"

export const staticURI = ""

// export const baseURL = "http://127.0.0.1:8000/uploads"
// export const baseURL2 = "http://127.0.0.1:8000"

export const formatDate = (tarikh) => {
    let date = new Date(tarikh)

    let year = date.getFullYear()
    let month = date.getMonth() + 1
    var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    let day = date.getDate()
    let monthName = months[date.getMonth()]

    return { day, month, year, monthName }
}

export const religions = ["Hindu", "Muslim", "Christian", "Sikh"]

export const scrollToTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export const major_codes = ["2701"]
export const ba_major_codes = ["6001"]

//export const net_slet_jrf_codes = ['0102', '0201', '0202', '0301', '0405', '0503', '0603', '0701', '0901', '1103', '1104', '1301', '1302', '1401', '1402', '1503', '1504', '1701', '1702', '1802', '1803', '1901', '1902', '2001', '2002', '2101', '2102', '2301', '2303', '2501', '2502', '2602', '2702', '2703', '0406', '0704', '0802'];

//export const net_slet_jrf_codes = ['1104', '1701', '1801', '1901', '2003', '2101', '2201', '2303', '2401', '2502', '2802', '2901', '3002', '3101', '3302', '3402', '3501', '3703', '3801', '3901', '4001', '4102', '4202', '4501'];

export const net_slet_jrf_codes = [
    "1604",
    "6004",
    "7004",
    "8004",
    "9004",
    "4404",
    "1304",
    "1104",
    "1204",
    "1404",
    "1704",
    "1804",
    "1904",
    "5504",
    "2104",
    "2204",
    "2304",
    "2404",
    "2604",
    "2704",
    "2804",
    "2904",
    "6604",
    "3104",
    "1004",
    "3404",
    "3304",
    "2504",
]

//export const gate_codes = ['0902', '1001']; //Mtech Courses

//export const gate_codes = ['2104', '2402']; //

export const gate_codes = ["1302", "4402"]

//export const preference_codes = ['1303', '1304']; //Mass Communication

//export const preference_codes = ['3401', '3403']; //Mass Communication

export const preference_codes = ["2302", "2305"] //Mass Communication

//export const bed_codes = ['0602']; //B.Ed

//export const bed_codes = ['2305']; //B.Ed

export const bed_codes = ["1201"] //B.Ed

export const unpaid_records = [
    "05mintudas@gmail.com",
    "priyamkapamehgam88660@gmail.com",
    "takuanna17.ta@gmail.com",
    "gcsgorik@gmail.com",
    "ssaarraamangkeng@gmail.com",
    "babukct1999@gmail.com",
    "pinkypao50@gmail.com",
    "mingjongkri@gmail.com",
    "chinmoymunda@gmail.com",
    "preeyanka57@gmail.com",
    "vikasahthapa@gmail.com",
    "Karadada179@gmail.com",
    "AKASHKUMAR.NIC.IN@GMAIL.COM",
    "nirentaid55@gmail.com",
    "AKASHKUMAR.NIC.IN@GMAIL.COM",
    "sakil1252@gmail.com",
    "mijong143@gmail.com",
    "vipula234@gmail.com",
    "aungthujanm@gmail.com",
    "yamarmakcha@gmail.com",
    "mihinyari1314@gmail.com",
    "bubunmahata003@gmail.com",
    "yamarmakcha@gmail.com",
    "iranlollen6@gmail.com",
    "chouhanchandra203@gmail.com",
    "kalingtaku25@gmail.com",
    "melengapi@gmail.com",
    "kashmirikumbang0@gmail.com",
    "pemkhandu07@gmail.com",
    "sakil1252@gmail.com",
    "brijeshgogoi@gmail.com",
    "budiampi29@gmail.com",
    "sakil1252@gmail.com",
    "pinchumarde45@gmail.com",
    "mrityunjoypegu9@gmail.com",
    "tallodulley500@gmail.com",
    "nabamsangte2020@gmail.com",
    "tagiataratan98@gmail.com",
    "mrityunjoypegu9@gmail.com",
    "hiffomeniang@gmail.com",
    "jumitali2001@gmail.com",
    "tamardui67@gmail.com",
    "ranjitakonwar21@gmail.com",
    "pegutwiss71@gmail.com",
    "budiampi29@gmail.com",
    "deep.biswas003@gmail.com",
    "pegutwiss71@gmail.com",
    "maxtelyommin91@gmail.com",
    "monikadoley702@gmail.com",
    "pgaonkhowa526@gmail.com",
    "suraj12shyam@gmail.com",
    "sippiofficial66@gmail.com",
    "pplabana@gmail.com",
    "utpallagachu72@gmail.com",
    "sonamengti14@gmail.com",
    "rakhi29kumari@gmail.com",
    "pujakonwar66@gmail.com",
    "akabake363@gmail.com",
    "www.okentaga.com@gmail.com",
    "sakil1252@gmail.com",
    "rulingtikhak618@gmail.com",
    "bethshebawahlang@gmail.com",
    "rulingtikhak618@gmail.com",
    "onyokmegu33@gmail.com",
    "onyokmegu33@gmail.com",
    "yapijamoh01@gmail.com",
    "eindrohmodih8@gmail.com",
    "eindrohmodih8@gmail.com",
    "pagagricha703@gmail.com",
    "leiki4@gmail.com",
    "jonirava03@gmail.com",
    "marlintai2018@gmail.com",
    "ribakaku80@gmail.com",
    "wangsukhunlaw@gmail.com",
    "leikitsering823@gmail.com",
    "wangnowhamwinpong@gmail.com",
    "dimpirabha000@gmail.com",
    "gogoimandeepa@gmail.com",
    "nainagogoi0@gmail.com",
    "gogoimandeepa@gmail.com",
    "jinidevi2000@gmail.com",
    "prastutiborah35@gmail.com",
    "pubalisaikia211@gmail.com",
    "chouhanchandra203@gmail.com",
    "aloknwd9597@gmail.com",
    "aloknwd9597@gmail.com",
]
