import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { showModal } from "../../../actions"
import PageModal from "../includes/modal/PageModal"
import BulletinForm from "../navbar/BulletinForm"
import "./footer.css"

class Footer extends Component {
    showModal = (name) => {
        this.props.showModal(name)
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section bg-dark-blue">
                    <div className="container mx-auto py-10 px-4">
                        <div className="footer-logo">
                            <img src="/icon/rgu.png" alt="logo" />
                        </div>
                        <ul className="flex flex-wrap justify-center mb-4 footer-links text-white">
                            {/* <li>
                                <Link to="/course-details">Programmes</Link>
                            </li> */}
                            <li>
                                <a
                                    href="https://rgu.ac.in"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    RGU Website
                                </a>
                            </li>
                            {/* <li>
                                <a href="#0" onClick={() => this.showModal('bulletinModal3')}>Admission Bulletin</a>
                            </li> */}
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/disclaimer">Disclaimer</Link>
                            </li>
                            <li>
                                <Link to="#0">Terms &amp; Conditions</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                        <div className="text-center text-white">
                            &copy; All Rights Reserved. Rajiv Gandhi University
                        </div>
                    </div>
                </footer>

                {this.props.modal.modalOpen &&
                this.props.modal.modalName === "bulletinModal3" ? (
                    <PageModal>
                        <BulletinForm />
                    </PageModal>
                ) : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    }
}

export default connect(mapStateToProps, { showModal })(Footer)
