import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import {
    getPersonalDetails,
    getBasicDetails,
    getEducationDetails,
} from "../../../../actions"
import { baseURL, baseURL2, formatDate } from "../../includes/jsFunctions"
//import ProgressBar from '../../dashboard/progressbar/ProgressBar';
//import { net_slet_jrf_codes, gate_codes, preference_codes, bed_codes } from '../../includes/jsFunctions';
import "./printpreview.css"

class PrintPreview extends Component {
    componentDidMount() {
        this.props.getPersonalDetails(this.props.match.params.id)
        this.props.getBasicDetails(this.props.match.params.id)
        this.props.getEducationDetails(this.props.match.params.id)
    }

    PrintDiv = () => {
        let divContents = document.getElementById("printdivcontent").innerHTML
        // let printWindow = window.open('', '', 'height=700,width=700');
        // printWindow.document.write('<html><head><title>Payment Receipt - Rajiv Gandhi University Admissions 2020</title>');
        // printWindow.document.write('</head><body >');
        // printWindow.document.write(divContents);
        // printWindow.document.write('</body></html>');
        // printWindow.document.close();
        // printWindow.print();

        var originalContents = document.body.innerHTML
        document.body.innerHTML = divContents
        window.print()
        document.body.innerHTML = originalContents
    }

    render() {
        const get_application_id = this.props.match.params.id

        const { loginLoading, login } = this.props.user
        const { stepOne, stepTwo, stepThree, qualifications } = this.props.apply

        const {
            community_details,
            ews_details,
            serviceman_details,
            disability_details,
            supernumerary_details,
            sports_details,
            kashmiri_details,
            ncc_details,
        } = stepTwo
        const { rgu_std_details, employment_details } = stepThree

        // if(!loginLoading && !login){
        //     window.location.href = "/";
        // }

        // if(stepTwo.status === 'Y'){
        //     window.location.href = "/dashboard";
        // }

        let permanent_address = {},
            current_address = {}

        if (stepOne && stepOne.addresses) {
            for (var i = 0; i < stepOne.addresses.length; i++) {
                if (stepOne.addresses[i].type === "P") {
                    permanent_address = stepOne.addresses[i]
                } else if (stepOne.addresses[i].type === "C") {
                    current_address = stepOne.addresses[i]
                }
            }
        }

        let DOB = formatDate(stepOne.dob)

        let religion, gender, bloodGroup, community, caste

        if (stepOne.religion === "1") {
            religion = "Hinduism"
        } else if (stepOne.religion === "2") {
            religion = "Islam"
        } else if (stepOne.religion === "3") {
            religion = "Christianity"
        } else if (stepOne.religion === "4") {
            religion = "Sikhism"
        } else if (stepOne.religion === "5") {
            religion = "Jainism"
        } else if (stepOne.religion === "6") {
            religion = "Buddhism"
        } else if (stepOne.religion === "7") {
            religion = "Others"
        }

        if (stepOne.gender === "F") {
            gender = "Female"
        } else if (stepOne.gender === "M") {
            gender = "Male"
        } else {
            gender = "Other"
        }

        if (stepOne.blood_group === "1") {
            bloodGroup = "A"
        } else if (stepOne.blood_group === "2") {
            bloodGroup = "B"
        } else if (stepOne.blood_group === "3") {
            bloodGroup = "AB"
        } else if (stepOne.blood_group === "4") {
            bloodGroup = "O"
        } else if (stepOne.blood_group === "5") {
            bloodGroup = "A+"
        } else if (stepOne.blood_group === "6") {
            bloodGroup = "B+"
        } else if (stepOne.blood_group === "7") {
            bloodGroup = "AB+"
        } else if (stepOne.blood_group === "8") {
            bloodGroup = "O+"
        } else if (stepOne.blood_group === "9") {
            bloodGroup = "A-"
        } else if (stepOne.blood_group === "10") {
            bloodGroup = "B-"
        } else if (stepOne.blood_group === "11") {
            bloodGroup = "AB-"
        } else if (stepOne.blood_group === "12") {
            bloodGroup = "O-"
        }

        if (stepTwo && stepTwo.community_details) {
            if (community_details.community === "1") {
                caste = "General"

                community = (
                    <ul className="preview-li">
                        <li>
                            Do you belong to EWS:{" "}
                            {stepTwo.ews === "Y" ? `Yes` : `No`}
                        </li>

                        {stepTwo.ews === "Y" ? (
                            <React.Fragment>
                                <li>
                                    Certificate No: {ews_details.certificate_no}
                                </li>
                                <li>Issue Date: {ews_details.issue_date}</li>
                                <li>
                                    Issue Authority:{" "}
                                    {ews_details.issue_authority}
                                </li>
                            </React.Fragment>
                        ) : null}
                    </ul>
                )
            } else if (parseInt(community_details.community) > 1) {
                if (community_details.community === "2") {
                    caste = "OBC-NCL"
                } else if (community_details.community === "3") {
                    caste = "SC"
                } else if (community_details.community === "4") {
                    caste = "ST"
                } else if (community_details.community === "5") {
                    caste = "APST"
                }

                community = (
                    <ul className="preview-li">
                        <li>Caste/Tribe: {community_details.caste}</li>
                        <li>
                            Certificate No: {community_details.certificate_no}
                        </li>
                        <li>Issue Date: {community_details.issue_date}</li>
                        <li>
                            Issuing Authority:{" "}
                            {community_details.issue_authority}
                        </li>
                        {community_details.community === "2" ? (
                            <React.Fragment>
                                <li>
                                    NCL Certificate No:{" "}
                                    {community_details.ncl_cetificate &&
                                    community_details.ncl_cetificate !==
                                        "undefined"
                                        ? community_details.ncl_cetificate
                                        : null}
                                </li>
                                <li>
                                    NCL Issue Date:{" "}
                                    {community_details.ncl_issue_date &&
                                    community_details.ncl_issue_date !==
                                        "undefined"
                                        ? community_details.ncl_issue_date
                                        : null}
                                </li>
                                <li>
                                    NCL Issuing Authority:{" "}
                                    {community_details.ncl_authority &&
                                    community_details.ncl_authority !==
                                        "undefined"
                                        ? community_details.ncl_authority
                                        : null}
                                </li>
                            </React.Fragment>
                        ) : null}
                    </ul>
                )
            }
        }

        const table_rows =
            qualifications && qualifications.length > 0
                ? qualifications.map((edu) => {
                      let exam_name, exam_status

                      if (edu.exam === "1") {
                          exam_name = "+2"
                      } else if (edu.exam === "2") {
                          exam_name = "+3"
                      } else if (edu.exam === "3") {
                          exam_name = "PG"
                      } else if (edu.exam === "4") {
                          exam_name = "M.Phil"
                      } else if (edu.exam === "5") {
                          exam_name = "Any Other"
                      }

                      if (edu.completed === "1") {
                          exam_status = "Completed"
                      } else if (edu.completed === "2") {
                          exam_status = "Appearing"
                      }

                      const honours =
                          edu.subjects && edu.subjects.length > 0
                              ? edu.subjects.map((subject) => {
                                    return `${subject.subject}, `
                                })
                              : null

                      return (
                          <tr key={edu.id}>
                              <td>{exam_name}</td>
                              <td>{edu.exam_name}</td>
                              <td>{exam_status}</td>
                              <td>{edu.board_details.name}</td>
                              <td>{edu.roll_no}</td>
                              <td>{edu.registration_no}</td>
                              <td>{edu.passing_year}</td>
                              <td>{edu.marks}</td>
                              <td>{edu.marks_secured}</td>
                              <td>{edu.percentage}</td>
                              <td>{honours}</td>
                          </tr>
                      )
                  })
                : null

        return (
            <React.Fragment>
                <ApplyBanner title="View Application" />

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    {/* <ProgressBar step="4" /> */}
                    <div className="container mx-auto px-8">
                        <div
                            className="form-wrap py-8 bg-white"
                            id="printdivcontent"
                        >
                            <div className="flex justify-center space-x-6 items-center mb-4">
                                <div className="">
                                    <img
                                        src="/icon/rgu.png"
                                        alt="logo"
                                        className="w-24"
                                    />
                                </div>
                                <div>
                                    <img
                                        alt="G20"
                                        src="/images/g20-lg.png"
                                        className=" w-32"
                                    />
                                </div>
                            </div>

                            <div className="mb-4 text-center">
                                <h1 className="font-medium text-xl">
                                    RAJIV GANDHI UNIVERSITY
                                </h1>
                                <h3>
                                    Rono Hills, Doimukh, Arunachal Pradesh-
                                    791112
                                </h3>
                            </div>

                            <div className="grid grid-cols-12">
                                <div className="border border-gray-300 col-span-6">
                                    <ul className="preview-li">
                                        <li>
                                            Application No:{" "}
                                            {stepOne.application_id}
                                        </li>
                                        <li>
                                            Course:{" "}
                                            {stepThree.course
                                                ? stepThree.course.title
                                                : ""}
                                        </li>
                                        <li>
                                            Candidate Name:{" "}
                                            {`${stepOne.fname} ${stepOne.mname} ${stepOne.lname}`}
                                        </li>
                                        <li>Father's Name: {stepOne.father}</li>
                                        <li>Mother's Name: {stepOne.mother}</li>
                                        <li>Email: {stepOne.email}</li>
                                        <li>Phone: {stepOne.mobile}</li>
                                        <li>Blood Group: {bloodGroup}</li>
                                    </ul>
                                </div>
                                <div className="border border-gray-300 col-span-6">
                                    <div className="text-center mb-1">
                                        <img
                                            src={`${baseURL}/photos/${stepOne.image}`}
                                            alt="applicant-photo"
                                            style={{
                                                width: "100px",
                                                margin: "0 auto",
                                            }}
                                        />
                                    </div>
                                    <ul className="preview-li border-t-2">
                                        <li>
                                            Date Of Birth:{" "}
                                            {`${DOB.day}-${DOB.month}-${DOB.year}`}
                                        </li>
                                        <li>Gender: {gender}</li>
                                        <li>Religion: {religion}</li>
                                        <li>
                                            Aadhaar Number: {stepOne.aadhaar_no}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 mt-1">
                                <div className="border border-gray-300">
                                    <ul className="preview-li">
                                        <li className="font-bold text-lg">
                                            Present Address
                                        </li>
                                        <li>
                                            Address Line 1:{" "}
                                            {current_address.address}
                                        </li>
                                        <li>
                                            Address Line 2:{" "}
                                            {current_address.adr_line_2}
                                        </li>
                                        <li>
                                            Country:{" "}
                                            {current_address.country_details
                                                ? current_address
                                                      .country_details.name
                                                : null}
                                        </li>
                                        <li>
                                            State:{" "}
                                            {current_address.state_details
                                                ? current_address.state_details
                                                      .name
                                                : null}
                                        </li>
                                        <li>
                                            City:{" "}
                                            {current_address.city_details
                                                ? current_address.city_details
                                                      .name
                                                : null}
                                        </li>
                                        <li>Pincode: {current_address.pin}</li>
                                    </ul>
                                </div>
                                <div className="border border-gray-300">
                                    <ul className="preview-li">
                                        <li className="font-bold text-lg">
                                            Permanent Address
                                        </li>
                                        <li>
                                            Address Line 1:{" "}
                                            {permanent_address.address}
                                        </li>
                                        <li>
                                            Address Line 2:{" "}
                                            {permanent_address.adr_line_2}
                                        </li>
                                        <li>
                                            Country:{" "}
                                            {permanent_address.country_details
                                                ? permanent_address
                                                      .country_details.name
                                                : null}
                                        </li>
                                        <li>
                                            State:{" "}
                                            {permanent_address.state_details
                                                ? permanent_address
                                                      .state_details.name
                                                : null}
                                        </li>
                                        <li>
                                            City:{" "}
                                            {permanent_address.city_details
                                                ? permanent_address.city_details
                                                      .name
                                                : null}
                                        </li>
                                        <li>
                                            Pincode: {permanent_address.pin}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 mt-1">
                                <div
                                    className="border border-gray-300 px-4 py-2 h-full"
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Caste: {caste}
                                </div>
                                <div className="border border-gray-300">
                                    {community}
                                </div>

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.serviceman === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Are you a Ward of Ex-Serviceman/Serviceman?
                                    :{" "}
                                    {stepTwo.serviceman === "Y" ? `Yes` : `No`}
                                </div>

                                {stepTwo.serviceman === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Last Designation held by Parents
                                                / Present Designation:{" "}
                                                {serviceman_details.designation}
                                            </li>
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    serviceman_details.certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {serviceman_details.issue_date}
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {
                                                    serviceman_details.issue_authority
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.disabled === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Are you a Differently-abled person? :{" "}
                                    {stepTwo.disabled === "Y" ? `Yes` : `No`}
                                </div>

                                {stepTwo.disabled === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    disability_details.certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {disability_details.issue_date}
                                            </li>
                                            <li>
                                                Percentage Of Disability:{" "}
                                                {disability_details.percentage}
                                            </li>
                                            <li>
                                                Description Of Disability:{" "}
                                                {disability_details.description}
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.supernumerary === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Supernumerary Candidates (Are you a Ward
                                    /Spouse of RGU Employee?) :{" "}
                                    {stepTwo.supernumerary === "Y"
                                        ? `Yes`
                                        : `No`}
                                </div>

                                {stepTwo.supernumerary === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Designation of Parent:{" "}
                                                {
                                                    supernumerary_details.designation
                                                }
                                            </li>
                                            <li>
                                                Departments/Section:{" "}
                                                {
                                                    supernumerary_details.department
                                                }
                                            </li>
                                            <li>
                                                Relation with the applicant:{" "}
                                                {supernumerary_details.relation ===
                                                "1"
                                                    ? `Ward`
                                                    : `Spouse`}
                                            </li>
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    supernumerary_details.certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {
                                                    supernumerary_details.issue_date
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.sports_quota === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Are you applying under Sports Quota? :{" "}
                                    {stepTwo.sports_quota === "Y"
                                        ? `Yes`
                                        : `No`}
                                </div>

                                {stepTwo.sports_quota === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Name of Sport:{" "}
                                                {sports_details.name}
                                            </li>
                                            <li>
                                                Level of the Participation:{" "}
                                                {sports_details.level === "1"
                                                    ? `International`
                                                    : sports_details.level ===
                                                      "2"
                                                    ? `National`
                                                    : sports_details.level ===
                                                      "3"
                                                    ? `Inter-University at National Level`
                                                    : sports_details.level ===
                                                      "4"
                                                    ? "State Level"
                                                    : sports_details.level ===
                                                      "5"
                                                    ? "District Level"
                                                    : "Other"}
                                            </li>
                                            <li>
                                                Certificate No:{" "}
                                                {sports_details.certificate_no}
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {sports_details.issue_date}
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {sports_details.issue_authority}
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.kashmiri_migrant === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Whether Kashmiri Migrant? :{" "}
                                    {stepTwo.kashmiri_migrant === "Y"
                                        ? `Yes`
                                        : `No`}
                                </div>

                                {stepTwo.kashmiri_migrant === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Name of the Person in whose name
                                                the certificate is issued:{" "}
                                                {kashmiri_details.name}
                                            </li>
                                            <li>
                                                Relationship with the person:{" "}
                                                {kashmiri_details.relation ===
                                                "1"
                                                    ? `Self`
                                                    : kashmiri_details.relation ===
                                                      "2"
                                                    ? `Son`
                                                    : kashmiri_details.relation ===
                                                      "3"
                                                    ? `Daughter`
                                                    : `Spouse`}
                                            </li>
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    kashmiri_details.certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {kashmiri_details.issue_date}
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {
                                                    kashmiri_details.issue_authority
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.ncc === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Are you a B/C NCC Certificate Holder? :{" "}
                                    {stepTwo.ncc === "Y" ? `Yes` : `No`}
                                </div>

                                {stepTwo.ncc === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Certificate Category:{" "}
                                                {ncc_details.certificate_category ===
                                                "1"
                                                    ? `B`
                                                    : `C`}
                                            </li>
                                            <li>
                                                Certificate No:{" "}
                                                {ncc_details.certificate_no}
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {ncc_details.issue_date}
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {ncc_details.issue_authority}
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepTwo.nss === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Are you NSS Volunteer? :{" "}
                                    {stepTwo.nss === "Y" ? `Yes` : `No`}
                                </div>

                                {stepTwo.nss === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {stepTwo.nss_details.issue_date}
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .issue_authority
                                                }
                                            </li>
                                            <li>
                                                Hours of social work done:{" "}
                                                {stepTwo.nss_details.hours ===
                                                "1"
                                                    ? `More than or equal to 240 hours`
                                                    : stepTwo.nss_details
                                                          .hours === "2"
                                                    ? `More than or equal to 120-239 hours`
                                                    : `Less than 120 hours`}
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                {stepTwo.nss === "Y" ? (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 ${
                                            stepTwo.nss_details.nic === "N"
                                                ? `col-span-2`
                                                : null
                                        } h-full`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        NSS NIC (National Integration Camp)
                                        Attended? :{" "}
                                        {stepTwo.nss_details.nic === "Y"
                                            ? `Yes`
                                            : `No`}
                                    </div>
                                ) : null}

                                {stepTwo.nss === "Y" &&
                                stepTwo.nss_details &&
                                stepTwo.nss_details.nic === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .nic_certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .nic_issue_date
                                                }
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .nic_issue_authority
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                {stepTwo.nss === "Y" ? (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 ${
                                            stepTwo.nss_details.rdc === "N"
                                                ? `col-span-2`
                                                : null
                                        } h-full`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        RDC (Republic Day Camp) Attended ? :{" "}
                                        {stepTwo.nss_details.rdc === "Y"
                                            ? `Yes`
                                            : `No`}
                                    </div>
                                ) : null}

                                {stepTwo.nss === "Y" &&
                                stepTwo.nss_details &&
                                stepTwo.nss_details.rdc === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .rdc_certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .rdc_issue_date
                                                }
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .rdc_issue_authority
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                {stepTwo.nss === "Y" ? (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 ${
                                            stepTwo.nss_details.special_camp ===
                                            "N"
                                                ? `col-span-2`
                                                : null
                                        } h-full`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Special camp Attended ? :{" "}
                                        {stepTwo.nss_details.special_camp ===
                                        "Y"
                                            ? `Yes`
                                            : `No`}
                                    </div>
                                ) : null}

                                {stepTwo.nss === "Y" &&
                                stepTwo.nss_details &&
                                stepTwo.nss_details.special_camp === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Certificate No:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .special_camp_certificate_no
                                                }
                                            </li>
                                            <li>
                                                Date Of Issue:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .special_camp_issue_date
                                                }
                                            </li>
                                            <li>
                                                Issuing Authority:{" "}
                                                {
                                                    stepTwo.nss_details
                                                        .special_camp_issue_authority
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>

                            <div className="mt-1 education-qualification">
                                <div className="font-bold text-lg">
                                    <ul className="preview-li">
                                        <li>Educational Qualifications:</li>
                                    </ul>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Examination Passed</th>
                                            <th>Examination Name</th>
                                            <th>Status</th>
                                            <th>Board/University</th>
                                            <th>Roll No</th>
                                            <th>Registration No</th>
                                            <th>Year Of Passing</th>
                                            <th>Total Marks</th>
                                            <th>Marks Secured</th>
                                            <th>Percentage Of Marks</th>
                                            <th>Honours/Major Subjects</th>
                                        </tr>
                                    </thead>
                                    <tbody>{table_rows}</tbody>
                                </table>
                            </div>

                            {stepThree.course &&
                            stepThree.course.category === "E" ? (
                                <div className="border border-gray-300 mt-1">
                                    <ul className="preview-li">
                                        <li>
                                            RGUCET Examination Centre:{" "}
                                            {`${stepThree.exam_center.name}, ${stepThree.exam_center.city.name}, ${stepThree.exam_center.pincode}, ${stepThree.exam_center.state.name}, ${stepThree.exam_center.country.name}, `}
                                        </li>
                                    </ul>
                                </div>
                            ) : null}

                            <div className="grid grid-cols-2 mt-1">
                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepThree.rgu_student === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Have you completed / Are you going to
                                    complete your Graduation from Rajiv Gandhi
                                    University? :{" "}
                                    {stepThree.rgu_student === "Y"
                                        ? `Yes`
                                        : `No`}
                                </div>

                                {stepThree.rgu_student === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Name of the College /
                                                Department:{" "}
                                                {rgu_std_details.college}
                                            </li>
                                            <li>
                                                Name of Course undertaken:{" "}
                                                {rgu_std_details.course}
                                            </li>
                                            <li>
                                                Registration No :{" "}
                                                {rgu_std_details.regd_no}
                                            </li>
                                            <li>
                                                Year of Completion / Expected
                                                year of Completion:{" "}
                                                {rgu_std_details.year}
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                {stepThree.gate ? (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 ${
                                            stepThree.gate === "N"
                                                ? `col-span-2`
                                                : null
                                        } h-full`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Are you qualified for GATE? :{" "}
                                        {stepThree.gate === "Y" ? `Yes` : `No`}
                                    </div>
                                ) : null}

                                {stepThree.gate === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>Year: {stepThree.gate_year}</li>
                                            <li>
                                                GATE Score:{" "}
                                                {stepThree.gate_score}
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}

                                <div
                                    className={`border border-gray-300 px-4 py-2 ${
                                        stepThree.employed === "N"
                                            ? `col-span-2`
                                            : null
                                    } h-full`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Are you employed? :{" "}
                                    {stepThree.employed === "Y" ? `Yes` : `No`}
                                </div>

                                {stepThree.employed === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            <li>
                                                Employer:{" "}
                                                {employment_details.employer}
                                            </li>
                                            <li>
                                                Designation:{" "}
                                                {employment_details.designation}
                                            </li>
                                            <li>
                                                Status of Employment:{" "}
                                                {employment_details.status ===
                                                "1"
                                                    ? `Regular`
                                                    : employment_details.status ===
                                                      "2"
                                                    ? `Contractual`
                                                    : `Contingency`}
                                            </li>
                                            <li>
                                                NOC Obtained:{" "}
                                                {employment_details.noc === "Y"
                                                    ? `Yes`
                                                    : `No`}
                                            </li>

                                            {employment_details.noc === "Y" ? (
                                                <React.Fragment>
                                                    <li>
                                                        Certificate No:{" "}
                                                        {
                                                            employment_details.noc_certificate_no
                                                        }
                                                    </li>
                                                    <li>
                                                        Date Of Issue:{" "}
                                                        {
                                                            employment_details.noc_issue_date
                                                        }
                                                    </li>
                                                    <li>
                                                        Issuing Authority:{" "}
                                                        {
                                                            employment_details.noc_issue_authority
                                                        }
                                                    </li>
                                                </React.Fragment>
                                            ) : null}
                                        </ul>
                                    </div>
                                ) : null}

                                {stepThree.net_qualified ? (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 ${
                                            stepThree.net_qualified === "N"
                                                ? `col-span-2`
                                                : null
                                        } h-full`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Are you qualified for NET/SLET/JRF? :{" "}
                                        {stepThree.net_qualified === "Y"
                                            ? `Yes`
                                            : `No`}
                                    </div>
                                ) : null}

                                {stepThree.net_qualified === "Y" ? (
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            {stepThree.net_slet_jrf &&
                                            stepThree.net_slet_jrf.length > 0
                                                ? stepThree.net_slet_jrf.map(
                                                      (item) => {
                                                          let net_qualification
                                                          if (
                                                              parseInt(
                                                                  item.qualification
                                                              ) === 1
                                                          ) {
                                                              net_qualification =
                                                                  "NET"
                                                          } else if (
                                                              parseInt(
                                                                  item.qualification
                                                              ) === 2
                                                          ) {
                                                              net_qualification =
                                                                  "SLET"
                                                          } else if (
                                                              parseInt(
                                                                  item.qualification
                                                              ) === 3
                                                          ) {
                                                              net_qualification =
                                                                  "JRF"
                                                          }

                                                          return (
                                                              <li>
                                                                  Qualification:{" "}
                                                                  {
                                                                      net_qualification
                                                                  }
                                                                  , Certificate
                                                                  No:{" "}
                                                                  {
                                                                      item.certficate_no
                                                                  }
                                                                  , Issue Date:{" "}
                                                                  {item.date},
                                                                  Authority:{" "}
                                                                  {
                                                                      item.authority
                                                                  }
                                                              </li>
                                                          )
                                                      }
                                                  )
                                                : null}
                                        </ul>
                                    </div>
                                ) : null}

                                {stepThree.course &&
                                stepThree.preference_one ? (
                                    <React.Fragment>
                                        <div
                                            className={`border border-gray-300 px-4 py-2`}
                                            style={{
                                                display: "flex",
                                                justifyContent: "left",
                                                alignItems: "center",
                                            }}
                                        >
                                            Preference One :{" "}
                                            {parseInt(
                                                stepThree.preference_one
                                            ) === 1
                                                ? `MA (Mass Communication)`
                                                : `PGDMC`}
                                        </div>
                                        <div className="border border-gray-300 px-4 py-2">
                                            Preference Two :{" "}
                                            {parseInt(
                                                stepThree.preference_two
                                            ) === 1
                                                ? `MA (Mass Communication)`
                                                : `PGDMC`}
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                {stepThree.discipline ? (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 col-span-2`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Discipline :{" "}
                                        {stepThree.discipline === "AC"
                                            ? `Arts/Commerce`
                                            : `Science`}
                                    </div>
                                ) : null}

                                {stepOne.major && (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 col-span-2`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Major Subject :{" "}
                                        {stepOne.major === "M"
                                            ? `Mathematics`
                                            : stepOne.major === "P"
                                            ? "Physics"
                                            : stepOne.major === "C"
                                            ? "Chemistry"
                                            : "Statistics"}
                                    </div>
                                )}

                                {stepOne.ba_major && (
                                    <div
                                        className={`border border-gray-300 px-4 py-2 col-span-2`}
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Major Subject :{" "}
                                        {stepOne.ba_major === "ANT"
                                            ? `Anthropology`
                                            : stepOne.ba_major === "PSY"
                                            ? "Psychology"
                                            : stepOne.ba_major === "SOC"
                                            ? "Sociology"
                                            : "Social Work"}
                                    </div>
                                )}
                            </div>

                            <div className="border border-gray-300 mt-1 flex justify-between px-4 py-2">
                                <img
                                    src={`${baseURL2}/qrcodes/${stepOne.qr_code}`}
                                    alt="qrcode"
                                />
                                <img
                                    src={`${baseURL}/signatures/${stepOne.signature}`}
                                    alt="signature"
                                    style={{ width: "150px" }}
                                />
                            </div>

                            {/* <div className="flex justify-center mt-4">
                                <Link to={`/apply/education-details/${get_application_id}`} className="mr-2 rgu-btn-light">Go Back</Link>
                                <Link to={`/apply/declaration/${get_application_id}`} className="rgu-btn-light">Continue</Link>
                            </div> */}

                            <div className="flex justify-center flex-wrap mt-6">
                                <img
                                    src="/govt-logos/10.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/11.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/12.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/13.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/14.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="text-center mt-4">
                            <button
                                type="button"
                                className="rgu-btn-light"
                                onClick={this.PrintDiv}
                            >
                                Print
                            </button>
                            <Link
                                to="/dashboard"
                                className="rgu-btn-light ml-2"
                            >
                                Dashboard
                            </Link>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getPersonalDetails,
        getBasicDetails,
        getEducationDetails,
    })(PrintPreview)
)
