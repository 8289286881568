import React from 'react';

import './facility-item.css';

const FacilityItem = ({ image, name }) => {
    return (
        <div className="facility-item mb-4">
            <div className="facility-image mb-4">
                <img src={image} alt={name} />
            </div>
            <div className="facility-name text-center text-white text-lg">
                {name}
            </div>
        </div>
    )
}

export default FacilityItem;
