import React, { Component } from "react"
import { Form, Formik } from "formik"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import Community from "./Community"
import EwsCategory from "./EwsCategory"
import Serviceman from "./Serviceman"
import Disability from "./Disability"
import Supernumerary from "./Supernumerary"
import Sports from "./Sports"
import Kashmiri from "./Kashmiri"
import Ncc from "./Ncc"
import Nss from "./Nss"
import validate from "./validationYup"
import { getBasicDetails, applyBasicDetails } from "../../../../actions"
import ProgressBar from "../../dashboard/progressbar/ProgressBar"
import LoadingBtn from "../../includes/btn/LoadingBtn"
import { staticURI } from "../../includes/jsFunctions"

class BasicDetails extends Component {
    componentDidMount() {
        this.props.getBasicDetails(this.props.match.params.id)
    }

    submitBasicDetailsFrom = (values, actions) => {
        //console.log(values)
        this.props.applyBasicDetails(values, actions)
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { stepTwo, status } = this.props.apply
        const { isSubmitting } = this.props.commonFunctions

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (stepTwo.status === "Y") {
            window.location.href = `${staticURI}/dashboard`
        }

        if (status) {
            window.location.href = `${staticURI}/apply/education-details/${stepTwo.application_id}`
        }

        const community_data =
            stepTwo && stepTwo.community_details
                ? stepTwo.community_details
                : {}
        const ews_data =
            stepTwo && stepTwo.ews_details ? stepTwo.ews_details : {}
        const serviceman_data =
            stepTwo && stepTwo.serviceman_details
                ? stepTwo.serviceman_details
                : {}
        const disability_data =
            stepTwo && stepTwo.disability_details
                ? stepTwo.disability_details
                : {}
        const supernumerary_data =
            stepTwo && stepTwo.supernumerary_details
                ? stepTwo.supernumerary_details
                : {}
        const sports_data =
            stepTwo && stepTwo.sports_details ? stepTwo.sports_details : {}
        const kashmiri_data =
            stepTwo && stepTwo.kashmiri_details ? stepTwo.kashmiri_details : {}
        const ncc_data =
            stepTwo && stepTwo.ncc_details ? stepTwo.ncc_details : {}
        const nss_data =
            stepTwo && stepTwo.nss_details ? stepTwo.nss_details : {}

        let sports_date =
            sports_data && sports_data.issue_date
                ? new Date(sports_data.issue_date)
                : ""
        let servieman_date =
            serviceman_data && serviceman_data.issue_date
                ? new Date(serviceman_data.issue_date)
                : ""
        let community_date =
            community_data && community_data.issue_date
                ? new Date(community_data.issue_date)
                : ""
        let community_ncl_date =
            community_data && community_data.ncl_issue_date
                ? new Date(community_data.ncl_issue_date)
                : ""
        let ews_date =
            ews_data && ews_data.issue_date ? new Date(ews_data.issue_date) : ""
        let disability_date =
            disability_data && disability_data.issue_date
                ? new Date(disability_data.issue_date)
                : ""
        let supernumerary_date =
            supernumerary_data && supernumerary_data.issue_date
                ? new Date(supernumerary_data.issue_date)
                : ""
        let kashmiri_date =
            kashmiri_data && kashmiri_data.issue_date
                ? new Date(kashmiri_data.issue_date)
                : ""
        let ncc_date =
            ncc_data && ncc_data.issue_date ? new Date(ncc_data.issue_date) : ""
        let nss_date =
            nss_data && nss_data.issue_date ? new Date(nss_data.issue_date) : ""
        let nic_date =
            nss_data && nss_data.nic_issue_date
                ? new Date(nss_data.nic_issue_date)
                : ""
        let rdc_date =
            nss_data && nss_data.rdc_issue_date
                ? new Date(nss_data.rdc_issue_date)
                : ""
        let camp_date =
            nss_data && nss_data.special_camp_issue_date
                ? new Date(nss_data.special_camp_issue_date)
                : ""

        return (
            <React.Fragment>
                <ApplyBanner title="Basic Details" />
                <section
                    className="personal-details-page py-10"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <ProgressBar step="2" />
                    <div className="container mx-auto px-8 mt-32">
                        <div className="form-wrap shadow-md bg-white pb-4">
                            <Formik
                                initialValues={{
                                    community: {
                                        name: community_data.community,
                                        caste: community_data.caste,
                                        certificate:
                                            community_data.certificate_no,
                                        date_of_issue: community_date,
                                        authority:
                                            community_data.issue_authority,
                                        ncl_certificate_no:
                                            community_data.ncl_cetificate,
                                        ncl_date_of_issue: community_ncl_date,
                                        ncl_authority:
                                            community_data.ncl_authority,
                                        ews: stepTwo.ews ? stepTwo.ews : "",
                                        ews_certificate:
                                            ews_data.certificate_no,
                                        ews_date_of_issue: ews_date,
                                        ews_authority: ews_data.issue_authority,
                                    },
                                    serviceman: {
                                        serviceman: stepTwo.serviceman,
                                        certificate:
                                            serviceman_data.certificate_no,
                                        date_of_issue: servieman_date,
                                        authority:
                                            serviceman_data.issue_authority,
                                        designation:
                                            serviceman_data.designation,
                                    },
                                    disability: {
                                        disable: stepTwo.disabled,
                                        certificate:
                                            disability_data.certificate_no,
                                        date_of_issue: disability_date,
                                        percentage: disability_data.percentage,
                                        description:
                                            disability_data.description,
                                    },
                                    supernumerary: {
                                        supernumerary: stepTwo.supernumerary,
                                        designation:
                                            supernumerary_data.designation,
                                        department:
                                            supernumerary_data.department,
                                        relation: supernumerary_data.relation,
                                        certificate:
                                            supernumerary_data.certificate_no,
                                        date_of_issue: supernumerary_date,
                                        authority:
                                            supernumerary_data.issue_authority,
                                    },
                                    sports: {
                                        quota: stepTwo.sports_quota,
                                        name: sports_data.name,
                                        level: sports_data.level,
                                        certificate: sports_data.certificate_no,
                                        date_of_issue: sports_date,
                                        authority: sports_data.issue_authority,
                                    },
                                    kashmiri: {
                                        kashmiri: stepTwo.kashmiri_migrant,
                                        name: kashmiri_data.name,
                                        relationship: kashmiri_data.relation,
                                        certificate:
                                            kashmiri_data.certificate_no,
                                        date_of_issue: kashmiri_date,
                                        authority:
                                            kashmiri_data.issue_authority,
                                    },
                                    ncc: {
                                        ncc: stepTwo.ncc,
                                        category: ncc_data.certificate_category,
                                        certificate: ncc_data.certificate_no,
                                        date_of_issue: ncc_date,
                                        authority: ncc_data.issue_authority,
                                    },
                                    nss: {
                                        nss: stepTwo.nss,
                                        category: nss_data.hours,
                                        certificate: nss_data.certificate_no,
                                        date_of_issue: nss_date,
                                        authority: nss_data.issue_authority,
                                        nic: nss_data.nic,
                                        nic_certificate:
                                            nss_data.nic_certificate_no,
                                        nic_date_of_issue: nic_date,
                                        nic_authority:
                                            nss_data.nic_issue_authority,
                                        rdc: nss_data.rdc,
                                        rdc_certificate:
                                            nss_data.rdc_certificate_no,
                                        rdc_date_of_issue: rdc_date,
                                        rdc_authority:
                                            nss_data.rdc_issue_authority,
                                        camp: nss_data.special_camp,
                                        camp_certificate:
                                            nss_data.special_camp_certificate_no,
                                        camp_date_of_issue: camp_date,
                                        camp_authority:
                                            nss_data.special_camp_issue_authority,
                                    },
                                    application_id: stepTwo.application_id,
                                }}
                                onSubmit={(values, actions) => {
                                    this.submitBasicDetailsFrom(values, actions)
                                }}
                                enableReinitialize={true}
                                //validate = {validate}
                                validationSchema={validate}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <Community props={props} />

                                        {props.values.community.name === "1" ? (
                                            <EwsCategory props={props} />
                                        ) : null}

                                        <Serviceman props={props} />

                                        <Disability props={props} />

                                        <Supernumerary props={props} />

                                        <Sports props={props} />

                                        <Kashmiri props={props} />

                                        <Ncc props={props} />

                                        <Nss props={props} />

                                        <div className="text-center mt-4 mb-4">
                                            <a
                                                href={`${staticURI}/apply/personal-details/${stepTwo.application_id}`}
                                                className="mr-2 rgu-btn-light"
                                            >
                                                Go Back
                                            </a>
                                            {isSubmitting ? (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="button"
                                                    disabled
                                                >
                                                    <LoadingBtn />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="submit"
                                                >
                                                    Save &amp; Next
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
    }
}

export default withRouter(
    connect(mapStateToProps, { getBasicDetails, applyBasicDetails })(
        BasicDetails
    )
)
