import React, { Component } from 'react'
import { FiChevronRight } from "react-icons/fi";

import { scrollToTop } from '../includes/jsFunctions';
import PageBanner from '../includes/page-banner/PageBanner';

class Disclaimer extends Component {
    componentDidMount(){
        scrollToTop();
    }

    render() {
        return (
            <React.Fragment>
                <PageBanner title="Disclaimer" image="/images/page.jpg" />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li><FiChevronRight /></li>
                                <li>Disclaimer</li>
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow-md mb-4">
                            
                            <h1 className="text-center font-bold text-lg md:text-xl">Disclaimer</h1>

                            <div className="mt-3">
                                <p>
                                The contents of this website are for general information only to enable the public at large to have a quick and an easy access to information and do not have any legal sanctity. Though every effort is made to provide accurate and updated information, it is likely that the some details such as telephone numbers, names of the officer holding a post, etc may have changed prior to their update in the website. Hence, we do not assume any legal liability on the completeness, accuracy or usefulness of the contents provided in this website.
                                </p>
                                <p className="mt-3">Rajiv Gandhi University does not assume any legal liability or responsibility for the accuracy completeness or usefulness of any information.</p>
                            </div>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Disclaimer;