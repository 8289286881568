import React, { Component } from 'react';
import {Field, FieldArray } from 'formik';
import { getIn } from 'formik';
import { connect } from 'react-redux';

import { fetchExamCenters } from '../../../../actions';
import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Qualification extends Component {

    componentDidMount(){
        this.props.fetchExamCenters();
    }

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.map(option => {
            return (
                <option value={option.id} key={option.id}>{option.name}</option>
            )
        });
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        const selectExam = [
            { id: 1, name: '+2'},
            { id: 2, name: '+3'},
            { id: 3, name: 'PG'},
            { id: 4, name: 'M.Phil'},
            { id: 5, name: 'Any Other'}
        ];

        const selectStatus = [{id: 1, name: 'Completed'}, {id: 2, name: 'Appearing'}];

        const { boardNames } = this.props.commonData;

        return (
            <div className="section-one px-8 py-4 border-b-gray-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                    <div>
                        <Field component={this.mySelectInput} name="edu.exam" label="Examination" options={selectExam}></Field>
                        {getIn(props.errors, 'edu.exam') && getIn(props.touched, 'edu.exam') && <ErrorMsg msg={getIn(props.errors, 'edu.exam')} />}
                    </div>

                    {
                        props.values.edu.exam ? (
                            <div>
                                <Field component={this.myTextInput} name="edu.exam_name" placeholder="Examination Name" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'edu.exam_name') && getIn(props.touched, 'edu.exam_name') && <ErrorMsg msg={getIn(props.errors, 'edu.exam_name')} />}
                            </div>
                        ) : null
                    }
                    
                    <div>
                        <Field component={this.mySelectInput} name="edu.status" label="Status" options={selectStatus}></Field>
                        {getIn(props.errors, 'edu.status') && getIn(props.touched, 'edu.status') && <ErrorMsg msg={getIn(props.errors, 'edu.status')} />}
                    </div>
                    <div>
                        <Field component={this.mySelectInput} name="edu.board" label="Board/University" options={boardNames}></Field>
                        {getIn(props.errors, 'edu.board') && getIn(props.touched, 'edu.board') && <ErrorMsg msg={getIn(props.errors, 'edu.board')} />}
                    </div>

                    <div>
                        <Field component={this.myTextInput} name="edu.roll" placeholder="Roll No" type="text" readonly={false}></Field>
                        {getIn(props.errors, 'edu.roll') && getIn(props.touched, 'edu.roll') && <ErrorMsg msg={getIn(props.errors, 'edu.roll')} />}
                    </div>
                    <div>
                        <Field component={this.myTextInput} name="edu.reg_no" placeholder="Registration No" type="text" readonly={false}></Field>
                        {getIn(props.errors, 'edu.reg_no') && getIn(props.touched, 'edu.reg_no') && <ErrorMsg msg={getIn(props.errors, 'edu.reg_no')} />}
                    </div>
                    <div>
                        <Field component={this.myTextInput} name="edu.year" placeholder="Year of Passing" type="number" readonly={false}></Field>
                        {getIn(props.errors, 'edu.year') && getIn(props.touched, 'edu.year') && <ErrorMsg msg={getIn(props.errors, 'edu.year')} />}
                    </div>
                </div>
                
                {
                    parseInt(props.values.edu.status) === 1 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                            <div>
                                <Field component={this.myTextInput} name="edu.total_marks" placeholder="Total Marks" type="number" readonly={false}></Field>
                                {getIn(props.errors, 'edu.total_marks') && getIn(props.touched, 'edu.total_marks') && <ErrorMsg msg={getIn(props.errors, 'edu.total_marks')} />}
                            </div>
                            <div>
                                <Field component={this.myTextInput} name="edu.marks_secured" placeholder="Marks Secured/Equivalent Marks" type="number" readonly={false}></Field>
                                {getIn(props.errors, 'edu.marks_secured') && getIn(props.touched, 'edu.marks_secured') && <ErrorMsg msg={getIn(props.errors, 'edu.marks_secured')} />}
                            </div>
                            <div>
                                <Field component={this.myTextInput} name="edu.percentage" placeholder="Percentage of Marks" type="number" readonly={false}></Field>
                                {getIn(props.errors, 'edu.percentage') && getIn(props.touched, 'edu.percentage') && <ErrorMsg msg={getIn(props.errors, 'edu.percentage')} />}
                            </div>
                        </div>
                    ) : null
                }

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                    <FieldArray
                        name="edu.subjects"
                        render={arrayHelpers => (
                        <div>
                            <div className="flex">
                                <div className="flex-initial self-center">Add Subjects / Honours / Major Subjects</div>
                                <button
                                    type="button"
                                    className="flex-initial ml-2 self-center"
                                    onClick={() => arrayHelpers.push('')}
                                >
                                    <img src="/icon/add-button.png" alt="remove" style={{ width: '30px' }} />
                                </button>
                            </div>
                            {props.values.edu.subjects && props.values.edu.subjects.length > 0 ? (
                            props.values.edu.subjects.map((subject, index) => (
                                <div key={index} className="flex">
                                    <Field component={this.myTextInput} name={`edu.subjects.${index}`} placeholder="Enter Subject" type="text" readonly={false}></Field>
                                    <button
                                        type="button"
                                        className="flex-initial ml-2 self-center"
                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                        <img src="/icon/delete-button.png" alt="remove" style={{ width: '40px', marginTop: '1rem' }} />
                                    </button>
                                </div>
                            ))
                            ) : null}
                            
                        </div>
                        )}
                    />
                    {getIn(props.errors, 'edu.subjects') && getIn(props.touched, 'edu.subjects') && <ErrorMsg msg={getIn(props.errors, 'edu.subjects')} />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps, { fetchExamCenters })(Qualification);