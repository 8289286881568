import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import NoticeItem from './NoticeItem';

class Notices extends Component {
    render() {

        const { notices } = this.props.appData;

        const notice_items = notices && notices.length > 0 ? notices.map( notice => { 
            return (
                <NoticeItem title={notice.title} key={notice.id} file={notice.filename} course={notice.course ? notice.course.title : null} />
            )
        }) : null;

        return (
            <div className="notice-items">
                {notice_items}
                <div className="text-center mt-8">
                    <Link to="/all-notices" className="rgu-btn-light">
                        View More
                    </Link>
                </div>                                                                                 
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        appData: state.appData
    }
}

export default connect( mapStateToProps )(Notices);
