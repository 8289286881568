import React, { Component } from 'react';

import FacilityItem from './FacilityItem';

class CampusFacilities extends Component {
    render() {
        return (
            <div className="tabs-section-one active" id="facilities">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-10">
                    <FacilityItem name="Transport Facilities" image="/icon/transport.png" />
                    <FacilityItem name="Bank and Post Office" image="/icon/bank-and-post-office.png" />
                    <FacilityItem name="Computer Center" image="/icon/computer-center.png" />
                    <FacilityItem name="Guest House" image="/icon/guest-house.png" />
                    <FacilityItem name="Health Center" image="/icon/health-center.png" />
                    <FacilityItem name="Hostel" image="/icon/hostel.png" />
                    <FacilityItem name="Library" image="/icon/library.png" />
                    <FacilityItem name="Vsat Facility" image="/icon/vsat.png" />
                </div>
            </div>
        )
    }
}

export default CampusFacilities;
