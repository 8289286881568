import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, Form, Formik, FormikProps, ErrorMessage} from 'formik';

import ErrorMsg from '../../../includes/form-inputs/ErrorMsg';
import { validateEmail } from './validate';
import LoadingBtn from '../../../includes/btn/LoadingBtn';
import { sendEmailOTP } from '../../../../../actions';
import ProgressBar from './ProgressBar';

class SendEmail extends Component {

    submitOtpFrom = (values, actions) => {
        this.props.sendEmailOTP(values, actions);
    }

    myTextInput = ({ name, field, placeholder, type, border }) => {
        const borderStyle = border ? `border border-gray-300 rounded` : null;
        return (
            <div className="w-full">
                <div className="text-input">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 ${borderStyle}`} {...field} />
                </div>
            </div>
        )
    }

    render() {

        const { mobile } = this.props.bannerForm;
        const { isSubmitting } = this.props.commonFunctions;
        const { current_year } = this.props.settings;

        return (
            <React.Fragment>
                <ProgressBar width="50%" />
                <div className="form-head text-center">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">Admission Open { current_year }</h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter your email address to continue
                    </p>
                </div>
                <div className="form-body mt-12 mb-8">
                    
                    <Formik
                        initialValues={{email: '', mobile}}
                        onSubmit={(values, actions) => {
                            this.submitOtpFrom(values, actions);
                        }}
                    >{(props: FormikProps<any>) => (
                        <Form>
                            <div className="w-full mt-4">
                                <Field component={this.myTextInput} name="email" validate={validateEmail} placeholder="Enter Email" type="email" border={true}></Field>
                                <ErrorMessage name="email" render={msg => <ErrorMsg msg={msg} />} />
                            </div>

                            <div id="resend-btn"></div>
                    
                            <div className={`form-submit text-center mt-8 mb-4`}>
                                <button type="submit" className="rgu-btn-light">
                                    {
                                        isSubmitting ? <LoadingBtn /> : `Send OTP`
                                    }
                                </button>
                            </div>

                        </Form>
                    )}
                    </Formik>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm,
        commonFunctions: state.commonFunctions,
        settings: state.commonData.settings
    }
}

export default connect(mapStateToProps, { sendEmailOTP })(SendEmail);