import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik, FormikProps, ErrorMessage } from "formik"

import ErrorMsg from "../../../includes/form-inputs/ErrorMsg"
import { sendOTP, showModal } from "../../../../../actions"
import LoadingBtn from "../../../includes/btn/LoadingBtn"
import PageModal from "../../../includes/modal/PageModal"
import LoginForm from "../../../navbar/LoginForm"
import ResetPassword from "../../../navbar/ResetPassword"
import ProgressBar from "./ProgressBar"
import { baseURL } from "../../../includes/jsFunctions"

class SendOtp extends Component {
    submitOtpFrom = (values, actions) => {
        this.props.sendOTP(values, actions)
    }

    showModal = (name) => {
        this.props.showModal(name)
    }

    validateMobile = (value) => {
        let error
        if (value === "") {
            error = "Please enter mobile number"
        } else if (value.length !== 10) {
            error = "Invalid mobile number"
        }
        return error
    }

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3`}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    render() {
        const { isSubmitting } = this.props.commonFunctions
        const { forgotPassword } = this.props.user
        const { registration, current_year } = this.props.settings

        let m_apply_open = true
        let m_today = new Date()
        let m_apply_last_date = new Date("2021-01-27")

        m_apply_last_date.setDate(m_apply_last_date.getDate() + 1)

        let m_add_one_day = new Date(
            m_apply_last_date.getFullYear() +
                "-" +
                ("0" + (m_apply_last_date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + m_apply_last_date.getDate()).slice(-2)
        )

        if (m_today.getTime() > m_add_one_day.getTime()) {
            m_apply_open = false
        }

        return (
            <React.Fragment>
                {registration ? <ProgressBar width="25%" /> : null}
                <div className="form-head text-center">
                    {/* <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        Admission {current_year}
                    </h1>
                    <h1 className="uppercase font-bold text-dark-blue text-lg">
                        Last date of Submission - 6th October 2023
                    </h1> */}
                    <h1 className="font-bold text-dark-blue text-lg">
                        As directed by the Ministry of Education, New Delhi, the
                        Admission registration link shall be through SAMARTH
                        portal only and not through the University portal. As
                        such, the admission link for SAMARTH portal shall be
                        notified very soon.
                    </h1>
                    {registration ? (
                        <p className="text-gray-600 text-lg mt-4">
                            Please enter your mobile number to continue
                        </p>
                    ) : (
                        <p className="text-gray-600 text-lg my-4 md:my-16">
                            Registration is closed.
                        </p>
                    )}
                </div>
                <div className="form-body mt-12 mb-6">
                    <Formik
                        initialValues={{ mobile: "" }}
                        onSubmit={(values, actions) => {
                            this.submitOtpFrom(values, actions)
                        }}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                {registration ? (
                                    <React.Fragment>
                                        <div className="form-input flex border border-gray-300 rounded">
                                            <select>
                                                <option>+91</option>
                                            </select>
                                            <Field
                                                component={this.myTextInput}
                                                validate={this.validateMobile}
                                                name="mobile"
                                                placeholder="Enter mobile number"
                                                type="text"
                                            ></Field>
                                        </div>
                                        <ErrorMessage
                                            name="mobile"
                                            render={(msg) => (
                                                <ErrorMsg msg={msg} />
                                            )}
                                        />
                                        <div className="text-center text-light-blue">
                                            eg: 8964098640
                                        </div>

                                        <div
                                            className={`form-submit text-center mt-8 mb-4`}
                                        >
                                            <button
                                                type="submit"
                                                className="rgu-btn-light"
                                            >
                                                {isSubmitting ? (
                                                    <LoadingBtn />
                                                ) : (
                                                    `Continue`
                                                )}
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                <hr />

                                <div className="text-center mt-2">
                                    <a
                                        href="#0"
                                        onClick={() =>
                                            this.showModal("loginModal2")
                                        }
                                    >
                                        Already Applied ? Login
                                    </a>
                                </div>

                                <div className="text-center mt-3 text-sm">
                                    {registration ? (
                                        <p>
                                            Please read &nbsp;
                                            <a
                                                href={`${baseURL}/howtoapply/how-to-apply.pdf`}
                                                rel="noreferrer noopener"
                                                className="font-bold"
                                                target="_blank"
                                            >
                                                "How to Apply"
                                            </a>
                                            &nbsp; manual before applying.
                                        </p>
                                    ) : null}
                                </div>

                                {this.props.modal.modalOpen &&
                                this.props.modal.modalName === "loginModal2" ? (
                                    forgotPassword ? (
                                        <PageModal>
                                            <ResetPassword />
                                        </PageModal>
                                    ) : (
                                        <PageModal>
                                            <LoginForm />
                                        </PageModal>
                                    )
                                ) : null}
                            </Form>
                        )}
                    </Formik>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bannerForm: state.bannerForm,
        commonFunctions: state.commonFunctions,
        user: state.user,
        modal: state.modal,
        settings: state.commonData.settings,
    }
}

export default connect(mapStateToProps, { sendOTP, showModal })(SendOtp)
