import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import {
    getAdmissionPaymentDetails,
    noFeeApplication,
    createAdmissionPaymentOrder,
} from "../../../../actions"
//import { baseURL, formatDate } from '../../includes/jsFunctions';
import LoadingBtn from "../../includes/btn/LoadingBtn"
import { staticURI } from "../../includes/jsFunctions"

class AdmissionPayment extends Component {
    componentDidMount() {
        this.props.getAdmissionPaymentDetails(this.props.match.params.id)
    }

    withFee = (id) => {
        this.props.createAdmissionPaymentOrder(id)
    }

    successModal = () => {
        console.log("success")
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { isSubmitting } = this.props.commonFunctions
        const {
            admissionPaymentData,
            payment,
            payment_success,
            payment_error,
        } = this.props.apply
        const { course, fname, mname, lname, mobile, email, application_id } =
            admissionPaymentData

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (admissionPaymentData.admission_payment === "Y") {
            window.location.href = `${staticURI}/dashboard`
        }

        if (payment) {
            window.location.href = `${staticURI}/dashboard`
        }

        return (
            <React.Fragment>
                <ApplyBanner title="Payment Details" />

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <div className="container mx-auto px-8 mt-8">
                        <div className="form-wrap py-8 bg-white">
                            <div className="text-center mb-4">
                                <img
                                    src="/icon/rgu.png"
                                    alt="logo"
                                    className="w-24"
                                    style={{ margin: "0 auto" }}
                                />
                                <h1 className="font-medium text-xl">
                                    RAJIV GANDHI UNIVERSITY
                                </h1>
                                <h3>
                                    Rono Hills, Doimukh, Arunachal Pradesh-
                                    791112
                                </h3>
                            </div>

                            <div className="px-8">
                                <div className="grid grid-cols-2 mt-1">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Name
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        {`${fname} ${mname} ${lname}`}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Mobile: {mobile}
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Email: {email}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Department:
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        {admissionPaymentData &&
                                        admissionPaymentData.department
                                            ? admissionPaymentData.department
                                                  .name
                                            : null}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Course:
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        {admissionPaymentData &&
                                        admissionPaymentData.course
                                            ? course.title
                                            : null}
                                    </div>
                                </div>

                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Fee Details
                                    </div>
                                    <div className="border border-gray-300">
                                        <ul className="preview-li">
                                            {admissionPaymentData &&
                                            admissionPaymentData.course
                                                ? admissionPaymentData.course.admission_fee.fee_types.map(
                                                      (fee) => {
                                                          return (
                                                              <li>
                                                                  {
                                                                      fee
                                                                          .particular
                                                                          .name
                                                                  }
                                                                  : Rs.{" "}
                                                                  {fee.amount}
                                                              </li>
                                                          )
                                                      }
                                                  )
                                                : null}
                                        </ul>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Total:
                                    </div>
                                    <div className="border border-gray-300 px-4 py-2">
                                        {
                                            admissionPaymentData &&
                                            admissionPaymentData.course
                                                ? `Rs. ${admissionPaymentData.course.admission_fee.amount}`
                                                : null
                                            //console.log(application_fee)
                                        }
                                    </div>
                                </div>

                                <div className="payment-btns mt-6 flex justify-center">
                                    {
                                        <button
                                            className="rgu-btn-light"
                                            type="button"
                                            onClick={() =>
                                                this.withFee(application_id)
                                            }
                                        >
                                            {isSubmitting ? (
                                                <LoadingBtn />
                                            ) : (
                                                `Pay Now`
                                            )}
                                        </button>
                                        //console.log(application_fee)
                                    }
                                </div>
                                <div
                                    className="mt-4 text-center text-red-500 font-bold text-lg hidden"
                                    id="payment-status"
                                >
                                    Attention: Your payment is processing.
                                    Please do not close the browser window until
                                    we redirect you.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getAdmissionPaymentDetails,
        noFeeApplication,
        createAdmissionPaymentOrder,
    })(AdmissionPayment)
)
