import React, { Component } from "react"
import { Field } from "formik"
import { getIn } from "formik"
import { connect } from "react-redux"

import ErrorMsg from "../../includes/form-inputs/ErrorMsg"

class MajorSubjects extends Component {
    myRadioInput = ({ text, field, name, checked, value, selected }) => {
        const border = checked
            ? `border border-blue-200`
            : `border border-gray-200`

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input
                        type="radio"
                        name={name}
                        {...field}
                        value={value}
                        checked={value === selected}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    render() {
        const { props } = this.props

        return (
            <div className="px-8 py-4 border-b-gray-300">
                <div className="">
                    <label>Choose Major Subject :</label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
                    <Field
                        component={this.myRadioInput}
                        name="course.major"
                        text="Physics"
                        value="P"
                        selected={props.values.course.major}
                    ></Field>
                    <Field
                        component={this.myRadioInput}
                        name="course.major"
                        text="Chemistry"
                        value="C"
                        selected={props.values.course.major}
                    ></Field>
                    <Field
                        component={this.myRadioInput}
                        name="course.major"
                        text="Mathematics"
                        value="M"
                        selected={props.values.course.major}
                    ></Field>
                    <Field
                        component={this.myRadioInput}
                        name="course.major"
                        text="Statistics"
                        value="S"
                        selected={props.values.course.major}
                    ></Field>
                </div>
                {getIn(props.errors, "course.major") &&
                    getIn(props.touched, "course.major") && (
                        <ErrorMsg msg={getIn(props.errors, "course.major")} />
                    )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps)(MajorSubjects)
