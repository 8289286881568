import React from "react"
import { Link } from "react-router-dom"

import ProgressBar from "../progressbar/ProgressBar"
import {
    redirect,
    makePayment,
    viewApplication,
    viewPaymentReceipt,
    viewAdmit,
    makeAdmissionPayment,
    viewAdmissionReceipt,
    unpaid_records,
} from "../../includes/jsFunctions"

const ApplicationItem = ({
    title,
    status,
    step,
    id,
    transaction_id,
    last_date,
    admit,
    course_type,
    appeared_exam,
    admission_fee,
    admission_notified,
    admission_transaction_id,
    email,
    is_shortlisted_admission,
}) => {
    const span = status === "Y" ? `col-span-4 md:col-span-3` : `col-span-4`

    let apply_open = true

    if (last_date) {
        let today = new Date()
        let apply_last_date = new Date(last_date)

        apply_last_date.setDate(apply_last_date.getDate() + 1)

        let add_one_day = new Date(
            apply_last_date.getFullYear() +
                "-" +
                ("0" + (apply_last_date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + apply_last_date.getDate()).slice(-2)
        )

        if (today.getTime() > add_one_day.getTime()) {
            apply_open = false
        }
    }

    /* Marksheet Last Date Check Start */
    let m_apply_open = true
    let m_today = new Date()
    let m_apply_last_date = new Date("2021-01-27")

    m_apply_last_date.setDate(m_apply_last_date.getDate() + 1)

    let m_add_one_day = new Date(
        m_apply_last_date.getFullYear() +
            "-" +
            ("0" + (m_apply_last_date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + m_apply_last_date.getDate()).slice(-2)
    )

    if (m_today.getTime() > m_add_one_day.getTime()) {
        m_apply_open = false
    }
    /* Marksheet Last Date Check End */

    return (
        <div className="shadow-md py-2 px-4 grid grid-cols-4 gap-4 mb-4 bg-white">
            <div className={`${span}`}>
                <h1 className="mb-4 text-dark-blue font-bold text-2xl">
                    {title}
                </h1>
                <div className="status-div flex justify-between text-lg">
                    <div className="status-div">
                        <span className="font-bold">Status - </span>
                        {status === "Y" ? (
                            <span className="text-green-500">Completed</span>
                        ) : (
                            <span className="text-red-500">Incomplete</span>
                        )}
                    </div>
                    {status === "N" &&
                    parseInt(step) < 5 &&
                    last_date &&
                    apply_open ? (
                        <div className="text-light-blue font-bold">
                            <button
                                onClick={() => redirect(step, id)}
                                type="button"
                                className="appearance-none"
                            >
                                Complete Your Application
                            </button>
                        </div>
                    ) : status === "N" &&
                      parseInt(step) === 5 &&
                      last_date &&
                      apply_open ? (
                        <div className="text-light-blue font-bold">
                            <button
                                onClick={() => makePayment(id)}
                                type="button"
                                className="appearance-none"
                            >
                                Make Payment
                            </button>
                        </div>
                    ) : null}
                    {status === "Y" && !transaction_id && (
                        <div className="text-light-blue font-bold">
                            <button
                                onClick={() => makePayment(id)}
                                type="button"
                                className="appearance-none"
                            >
                                Make Payment
                            </button>
                        </div>
                    )}
                </div>

                {status === "N" && last_date && !apply_open ? (
                    <div className="text-red-400">
                        * The last date to apply in this course is over.
                    </div>
                ) : null}

                <ProgressBar step={step} />
            </div>

            {status === "Y" ? (
                <div className="col-span-4 md:col-span-1 text-center download-btns">
                    <h1 className="mb-3 text-dark-blue font-bold text-2xl">
                        Downloads
                    </h1>
                    <div className="flex justify-between flex-row md:flex-none md:flex-col gap-2">
                        <button
                            className="rgu-btn-light dashboard-btn"
                            type="button"
                            onClick={() => viewApplication(id)}
                        >
                            Application Form
                        </button>
                        {transaction_id && (
                            <button
                                className="rgu-btn-dark dashboard-btn"
                                type="button"
                                onClick={() =>
                                    viewPaymentReceipt(transaction_id)
                                }
                            >
                                Money Receipt
                            </button>
                        )}

                        {admit ? (
                            <button
                                className="rgu-btn-orange bg-orange-700 dashboard-btn"
                                type="button"
                                onClick={() => viewAdmit(admit)}
                            >
                                Admit Card
                            </button>
                        ) : null}

                        {/* {
                                m_apply_open ? (
                                    <Link to={`/merit-document-upload/${id}`} className="rgu-btn-orange bg-green-500 dashboard-btn">Upload Document</Link>
                                ) : null
                            } */}

                        {
                            //admission_notified
                            is_shortlisted_admission ? (
                                admission_fee === "Y" ? (
                                    <button
                                        className="rgu-btn-orange bg-purple-700 dashboard-btn"
                                        type="button"
                                        onClick={() =>
                                            viewAdmissionReceipt(
                                                admission_transaction_id
                                            )
                                        }
                                    >
                                        Admission Fee Receipt
                                    </button>
                                ) : (
                                    <div className="text-white font-bold">
                                        <button
                                            onClick={() =>
                                                makeAdmissionPayment(id)
                                            }
                                            type="button"
                                            className="appearance-none bg-green-500"
                                        >
                                            Pay Admission Fee
                                        </button>
                                    </div>
                                )
                            ) : null
                        }
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default ApplicationItem
