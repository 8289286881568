import React, { Component } from "react"
import { connect } from "react-redux"
import BannerText from "./text/BannerText"
import BannerForm from "./form/BannerForm"

import "./banner.css"

class Banner extends Component {
    render() {
        const { current_year } = this.props.settings

        return (
            <section className="banner-section relative z-0">
                <div
                    className="site-banner"
                    style={{
                        backgroundImage: `url('/images/home-banner.jpg')`,
                    }}
                >
                    <div className="image-overlay">
                        <div className="bg-white">
                            <div className="w-full -mt-3 lg:mt-20 py-4 px-4 md:px-8 banner-notify grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 md:gap-4">
                                <div>
                                    <div className="banner-notify-item  border border-r-1 shadow-lg pb-4">
                                        <div className="flex justify-center ">
                                            <img
                                                src={`/icon/notify-4.png`}
                                                style={{ width: "50px" }}
                                            />
                                        </div>
                                        <h1>
                                            RGU CUET-PG: Admission to
                                            Postgraduate Programmes
                                        </h1>
                                        <p>
                                            Learn about eligibility criteria,
                                            exam structure, important dates, and
                                            how to apply for admission to your
                                            desired postgraduate programmes.
                                        </p>
                                        <div className="mt-3">
                                            <a
                                                className=" bg-blue-500 rounded text-white px-4 py-2"
                                                href="https://rgucuet.samarth.edu.in/pg/"
                                                target="_blank"
                                            >
                                                Click Here
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-notify-item  border border-r-1 shadow-lg pb-4">
                                    <div className="flex justify-center ">
                                        <img
                                            src={`/icon/notify-3.png`}
                                            style={{ width: "50px" }}
                                        />
                                    </div>
                                    <h1>
                                        RGU CUET-UG: Admission to Undergraduate
                                        Programmes
                                    </h1>
                                    <p>
                                        Learn about eligibility criteria, exam
                                        structure, important dates, and how to
                                        apply for admission to your desired
                                        undergraduate programmes.
                                    </p>
                                    <div className="mt-3">
                                        <a
                                            className=" bg-blue-500 rounded text-white px-4 py-2"
                                            href="https://rgucuet.samarth.edu.in"
                                            target="_blank"
                                        >
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                                <div className="banner-notify-item  border border-r-1 shadow-lg pb-4">
                                    <div className="flex justify-center ">
                                        <img
                                            src={`/icon/notify-2.png`}
                                            style={{ width: "50px" }}
                                        />
                                    </div>
                                    <h1>RGU PET: Ph. D. Entrance Test</h1>
                                    <p>
                                        Learn about eligibility criteria, exam
                                        structure, important dates, and how to
                                        apply for admission to your desired Ph.
                                        D. programmes.
                                    </p>
                                    <div className="mt-3">
                                        <a
                                            className=" bg-blue-500 rounded text-white px-4 py-2"
                                            href="https://rguadm.samarth.edu.in/"
                                            target="_blank"
                                        >
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                                <div className="banner-notify-item border border-r-1 shadow-lg ">
                                    <div className="flex justify-center ">
                                        <img
                                            src={`/icon/notify-1.png`}
                                            style={{ width: "50px" }}
                                        />
                                    </div>
                                    <h1>RGU CET: Common Entrance Test</h1>
                                    <p>
                                        Learn about eligibility criteria, exam
                                        structure, important dates, and how to
                                        apply for admission to your desired
                                        programmes.
                                    </p>
                                    <div className="mt-3">
                                        <a
                                            className=" bg-blue-500 rounded text-white px-4 py-2"
                                            href="https://rguadm.samarth.edu.in/"
                                            target="_blank"
                                        >
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                                <div className="banner-notify-item border border-r-1 shadow-lg ">
                                    <div className="flex justify-center ">
                                        <img
                                            src={`/icon/notify-1.png`}
                                            style={{ width: "50px" }}
                                        />
                                    </div>
                                    <h1>NCET 2024</h1>
                                    <p>
                                        Learn about eligibility criteria,
                                        important dates and how to apply for
                                        admission to your desired ITEP
                                        programmes.
                                    </p>
                                    <div className="mt-3">
                                        <a
                                            className=" bg-blue-500 rounded text-white px-4 py-2"
                                            href="https://rgucuet.samarth.edu.in/ncet/"
                                            target="_blank"
                                        >
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                                <div className="banner-notify-item border border-r-1 shadow-lg ">
                                    <div className="flex justify-center ">
                                        <img
                                            src={`/icon/notify-1.png`}
                                            style={{ width: "50px" }}
                                        />
                                    </div>
                                    <h1>
                                        RGU UG/PG: Undergraduate
                                        Programmes/Postgraduate Programmes
                                    </h1>
                                    <p>
                                        Learn about eligibility criteria,
                                        important dates, and how to apply for
                                        admission to your desired undergraduate
                                        programmes/postgraduate programs
                                    </p>
                                    <div className="mt-3">
                                        <a
                                            className=" bg-blue-500 rounded text-white px-4 py-2"
                                            href="https://rguadm.samarth.edu.in/"
                                            target="_blank"
                                        >
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-0 md:mt-0 px-4 pb-2">
                                <h1 className="font-bold text-xl text-center">
                                    Fee Payment for Admissions
                                </h1>
                                <div className="flex justify-center flex-wrap mt-2">
                                    <a
                                        href="https://rzp.io/l/D9xanPH3"
                                        target="_blank"
                                        className="mr-2 bg-blue-500 rounded text-white px-4 py-2 mb-1"
                                    >
                                        Seat Retention Fee
                                    </a>
                                    <a
                                        href="https://rgucuet.samarth.edu.in/pg/"
                                        target="_blank"
                                        className="bg-blue-500 rounded text-white px-4 py-2 mb-1 mr-2"
                                    >
                                        Admission Fees Payment
                                    </a>
                                    <a
                                        href="https://rzp.io/l/TOmoQeV"
                                        target="_blank"
                                        className="mr-2 bg-blue-500 rounded text-white px-4 py-2 mb-1"
                                    >
                                        Admission Fees for B.Ed (Paid Seat)
                                    </a>
                                    <a
                                        href="https://rzp.io/l/mB84woBqh"
                                        target="_blank"
                                        className="mr-2 bg-blue-500 rounded text-white px-4 py-2 mb-1"
                                    >
                                        Certificate Course Admission Fee
                                    </a>
                                    <a
                                        href="https://rzp.io/l/YULCt8Twoh"
                                        target="_blank"
                                        className="mr-2 bg-blue-500 rounded text-white px-4 py-2 mb-1"
                                    >
                                        Answer Key Challenge Fee
                                    </a>
                                    <a
                                        href="https://rgu.ac.in/results-rgucet-2024/"
                                        target="_blank"
                                        className="bg-blue-500 rounded text-white px-4 py-2 mb-1"
                                    >
                                        RGUCET-2024 RESULTS
                                    </a>
                                </div>
                            </div>

                            {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 banner-grid hidden">
                            <div className="col-span-1 hidden md:block lg:block xl:block">
                                <div className="banner-text">
                                    <BannerText year={current_year} />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="banner-form">
                                    <BannerForm />
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.commonData.settings,
    }
}

export default connect(mapStateToProps)(Banner)
