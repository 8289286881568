import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Gate extends Component {

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={value === selected} />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">

                <div className="mb-4">
                    <div>
                        <label>Are you qualified for GATE?</label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="course.gate.qualified" text="Yes" value="Y" selected={props.values.course.gate.qualified}></Field>
                            <Field component={this.myRadioInput} name="course.gate.qualified" text="No" value="N" selected={props.values.course.gate.qualified}></Field>
                        </div>
                        {getIn(props.errors, 'course.gate.qualified') && getIn(props.touched, 'course.gate.qualified') && <ErrorMsg msg={getIn(props.errors, 'course.gate.qualified')} />}
                    </div>
                </div>

                {
                    props.values.course.gate.qualified === 'Y' ? 
                    (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="form-input">
                                <Field component={this.myTextInput} name="course.gate.year" placeholder="Year" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'course.gate.year') && getIn(props.touched, 'course.gate.year') && <ErrorMsg msg={getIn(props.errors, 'course.gate.year')} />}
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="course.gate.score" placeholder="GATE Score" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'course.gate.score') && getIn(props.touched, 'course.gate.score') && <ErrorMsg msg={getIn(props.errors, 'course.gate.score')} />}
                            </div>
                            
                        </div>
                    ) : null
                }
                
            </div>
        )
    }
}

export default Gate;