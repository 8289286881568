import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { getAdmitCard } from "../../../../actions"
import {
    ba_major_codes,
    baseURL,
    baseURL2,
    bed_codes,
    major_codes,
} from "../../includes/jsFunctions"
import "./admit.css"

class AdmitCard extends Component {
    componentDidMount() {
        this.props.getAdmitCard(this.props.match.params.id)
    }

    PrintDiv = () => {
        let divContents = document.getElementById("printdivcontent").innerHTML
        // let printWindow = window.open('', '', 'height=700,width=700');
        // printWindow.document.write('<html><head><title>Payment Receipt - Rajiv Gandhi University Admissions 2020</title>');
        // printWindow.document.write('</head><body >');
        // printWindow.document.write(divContents);
        // printWindow.document.write('</body></html>');
        // printWindow.document.close();
        // printWindow.print();

        var originalContents = document.body.innerHTML
        document.body.innerHTML = divContents
        window.print()
        document.body.innerHTML = originalContents
    }

    render() {
        const { admit_card } = this.props.apply
        const { current_year } = this.props.settings

        const full_name =
            admit_card && admit_card.application
                ? `${admit_card.application.fname.toUpperCase()} ${admit_card.application.mname.toUpperCase()} ${admit_card.application.lname.toUpperCase()}`
                : null
        const address =
            admit_card && admit_card.application
                ? `${admit_card.application.exam_center.address_line1}, ${
                      admit_card.application.exam_center.address_line2 !==
                      "undefined"
                          ? admit_card.application.exam_center.address_line2 +
                            ","
                          : ""
                  } ${admit_card.application.exam_center.city.name}, ${
                      admit_card.application.exam_center.pincode
                  }, ${admit_card.application.exam_center.state.name}, ${
                      admit_card.application.exam_center.country.name
                  }`
                : null

        return (
            <React.Fragment>
                {/* <ApplyBanner title="Payment Receipt" /> */}

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    {/* <ProgressBar step="4" /> */}
                    <div className="container mx-auto px-8">
                        <div
                            className="form-wrap py-8 bg-white px-4 md:px-8 mt-8"
                            id="printdivcontent"
                        >
                            <div className="flex justify-between items-center flex-wrap">
                                <div className="flex flex-grow-0 bottom-m-1">
                                    <div>
                                        <div className="flex justify-center space-x-6 items-center mb-4">
                                            <div className="">
                                                <img
                                                    src="/icon/rgu.png"
                                                    alt="logo"
                                                    className="w-24"
                                                />
                                            </div>
                                            <div>
                                                <img
                                                    alt="G20"
                                                    src="/images/g20-lg.png"
                                                    className=" w-32"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-4 text-center">
                                            <h1 className="font-medium text-xl">
                                                RAJIV GANDHI UNIVERSITY
                                            </h1>
                                            <h3>
                                                Rono Hills, Doimukh, Arunachal
                                                Pradesh- 791112
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img
                                        src={`${baseURL}/photos/${
                                            admit_card && admit_card.application
                                                ? admit_card.application.image
                                                : null
                                        }`}
                                        alt="applicant-photo"
                                        style={{
                                            width: "100px",
                                            margin: "0 auto",
                                        }}
                                    />
                                </div>
                            </div>

                            <div className=" log45   pt-2  pl-16 pr-16 pb-16">
                                <h1 className="text-2xl text-center">
                                    RGUPET/RGUCET {current_year}
                                </h1>
                                <h1 className="font-bold text-center">
                                    <u>Admit Card / प्रवेश पत्र</u>
                                </h1>
                            </div>
                            <div className="container">
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Name:&nbsp; &nbsp; {full_name}
                                    </p>
                                    <p className="">
                                        Roll No:&nbsp; &nbsp;{" "}
                                        {admit_card.roll_no}
                                    </p>
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Department:&nbsp; &nbsp;{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.department
                                                  .name
                                            : null}
                                    </p>{" "}
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Course:&nbsp; &nbsp;{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.course
                                                  .title
                                            : null}
                                    </p>{" "}
                                    {admit_card &&
                                        bed_codes.includes(
                                            admit_card.application?.course?.code
                                        ) && (
                                            <p className="ml-4">
                                                Discipline:&nbsp; &nbsp;{" "}
                                                {admit_card.application
                                                    ?.discipline === "AC"
                                                    ? `Arts/Commerce`
                                                    : `Science`}
                                            </p>
                                        )}
                                    {admit_card &&
                                        major_codes.includes(
                                            admit_card.application?.course?.code
                                        ) && (
                                            <p className="ml-4">
                                                Major Subject:&nbsp; &nbsp;{" "}
                                                {admit_card.application
                                                    ?.major === "M"
                                                    ? `Mathematics`
                                                    : admit_card.application
                                                          ?.major === "P"
                                                    ? "Physics"
                                                    : admit_card.application
                                                          ?.major === "C"
                                                    ? "Chemistry"
                                                    : "Statistics"}
                                            </p>
                                        )}
                                    {admit_card &&
                                        ba_major_codes.includes(
                                            admit_card.application?.course?.code
                                        ) && (
                                            <p className="ml-4">
                                                Major Subject:&nbsp; &nbsp;{" "}
                                                {admit_card.application
                                                    ?.ba_major === "ANT"
                                                    ? `Anthropology`
                                                    : admit_card.application
                                                          ?.ba_major === "PSY"
                                                    ? "Psychology"
                                                    : admit_card.application
                                                          ?.ba_major === "SOC"
                                                    ? "Sociology"
                                                    : "Social Work"}
                                            </p>
                                        )}
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Examination Center:&nbsp; &nbsp;{" "}
                                        {admit_card &&
                                        admit_card.application &&
                                        admit_card.application.exam_center
                                            ? admit_card.application.exam_center
                                                  .name
                                            : null}
                                    </p>{" "}
                                    <p>
                                        Examination Center Code:&nbsp; &nbsp;{" "}
                                        {admit_card &&
                                        admit_card.application &&
                                        admit_card.application.exam_center
                                            ? admit_card.application.exam_center
                                                  .code
                                            : null}
                                    </p>{" "}
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Date of Entrance:&nbsp; &nbsp;{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.course
                                                  .entrance_date
                                            : null}
                                    </p>
                                    <p className="">
                                        Time:&nbsp; &nbsp;{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.course
                                                  .entrance_time
                                            : null}{" "}
                                        to{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.course
                                                  .entrance_end_time
                                            : null}
                                    </p>
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Exam Center Address:&nbsp; &nbsp;{" "}
                                        {address}
                                    </p>{" "}
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <p className="ml-4">
                                        Email:&nbsp; &nbsp;{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.email
                                            : null}
                                    </p>
                                    <p className="">
                                        Mobile Number:&nbsp; &nbsp;{" "}
                                        {admit_card && admit_card.application
                                            ? admit_card.application.mobile
                                            : null}
                                    </p>
                                </div>
                                <div className="border border-gray-300 p-4 flex justify-between">
                                    <div>
                                        <img
                                            src={`${baseURL}/signatures/${
                                                admit_card &&
                                                admit_card.application
                                                    ? admit_card.application
                                                          .signature
                                                    : null
                                            }`}
                                            alt="signature"
                                            style={{
                                                width: "150px",
                                                marginBottom: "1rem",
                                            }}
                                        />
                                        <p className="ml-4">
                                            Signature of the Candidate
                                        </p>
                                    </div>
                                    <div>
                                        <img
                                            src={`${baseURL2}/qrcodes/${admit_card.qr_code}`}
                                            alt="qrcode"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="border border-gray-300 p-4  ">
                                    <p className="ml-4"> N.B:</p>
                                    <div className="ml-6">
                                        <li>
                                            This card is to presented by the
                                            candidate and shown on demand on any
                                            day of the Examination.
                                        </li>
                                        <li>
                                            Candidates are allowed to bring BALL
                                            PEN ONLY inside the Test Hall.
                                        </li>
                                        <li>
                                            Mobile phones and bags are NOT
                                            allowed inside the Test Hall.
                                        </li>
                                        {/* <li>
                                            Candidates must strictly adhere to
                                            COVID-19 appropriate behavior and
                                            maintain COVID-19 SoP as issued by
                                            the State Government.
                                        </li> */}
                                        <div className="font-bold ml-6">
                                            ***** This is electronically
                                            generated and does not require any
                                            signature *****
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="border border-gray-300 p-4  ">
                                    <div>
                                        Officer-in-Charge
                                        Banderdewa/Hollongi/Gumto/Naharlagun is
                                        requested to kindly allow the bearer of
                                        this Admit Card to enter the check post
                                        for appearing in the RGUPET/RGUCET{" "}
                                        {current_year} examination on the given
                                        date(s).
                                        <br /> <br />
                                        Candidates must bring with them a Voter
                                        Id/Adhar Card/PAN Card/Driving Licence
                                        to the examination hall to prove their
                                        identity.
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center flex-wrap mt-6">
                                <img
                                    src="/govt-logos/10.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/11.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/12.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/13.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                                <img
                                    src="/govt-logos/14.jpg"
                                    alt="Fit India"
                                    style={{
                                        width: "100px",
                                        marginRight: "1rem",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="text-center mt-3">
                            <button
                                type="button"
                                className="rgu-btn-light"
                                onClick={this.PrintDiv}
                            >
                                Print
                            </button>
                            <Link
                                to="/dashboard"
                                className="rgu-btn-light ml-2"
                            >
                                Dashboard
                            </Link>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        apply: state.apply,
        settings: state.commonData.settings,
    }
}

export default withRouter(connect(mapStateToProps, { getAdmitCard })(AdmitCard))
