import { combineReducers } from "redux"

/* Manage Modal State */
const modalReducer = (
    state = { modalOpen: false, modalName: null },
    action
) => {
    switch (action.type) {
        case "OPEN_MODAL": {
            let newState = {
                ...state,
                modalOpen: true,
                modalName: action.payload,
            }
            return newState
        }
        case "CLOSE_MODAL": {
            let newState = { ...state, modalOpen: false, modalName: null }
            return newState
        }
        default:
            return state
    }
}

const bannerFormReducer = (
    state = {
        otp: false,
        verified: false,
        registered: false,
        mobile: null,
        step: 1,
        user: {},
        email: null,
        emailVerify: false,
        emailOTP: false,
        resendSMS: false,
        resendMail: false,
    },
    action
) => {
    switch (action.type) {
        case "SEND_OTP": {
            if (action.payload.status === 200) {
                //let newState = { ...state, otp: true, mobile: action.payload.data.mobile };
                let newState = {
                    ...state,
                    otp: true,
                    mobile: action.payload.data.mobile,
                }
                return newState
            } else {
                //let newState = { ...state, otp: false, mobile: null };
                let newState = { ...state, otp: false, mobile: null }
                return newState
            }
        }
        case "VERIFY_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, verified: true }
                return newState
            } else {
                let newState = { ...state, verified: false }
                return newState
            }
        }
        case "RESEND_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, resendSMS: true }
                return newState
            } else {
                let newState = { ...state, resendSMS: false }
                return newState
            }
        }
        case "RESEND_EMAIL_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, resendMail: true }
                return newState
            } else {
                let newState = { ...state, resendMail: false }
                return newState
            }
        }
        case "RESET_FROM": {
            let newState = {
                ...state,
                otp: false,
                verified: false,
                mobile: null,
            }
            return newState
        }
        case "REGISTER_STEP_ONE": {
            if (action.payload.status === 200) {
                let newState = { ...state, step: 2 }
                return newState
            } else {
                let newState = { ...state, step: 1 }
                return newState
            }
        }
        case "SEND_EMAIL_OTP": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    emailOTP: true,
                    email: action.payload.data.email,
                    emailVerify: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    emailOTP: false,
                    email: null,
                    emailVerify: false,
                }
                return newState
            }
        }
        case "VERIFY_EMAIL_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, emailVerify: true }
                return newState
            } else {
                let newState = { ...state, emailVerify: false }
                return newState
            }
        }
        case "REGISTER_STEP_TWO": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    registered: true,
                    user: action.payload.data.user,
                }
                return newState
            } else {
                let newState = { ...state, registered: false, user: {} }
                return newState
            }
        }
        default:
            return state
    }
}

const commonFunctionsReducer = (
    state = {
        isLoading: false,
        isSubmitting: false,
    },
    action
) => {
    switch (action.type) {
        case "START_LOADING": {
            let newState = { ...state, isLoading: true }
            return newState
        }
        case "STOP_LOADING": {
            let newState = { ...state, isLoading: false }
            return newState
        }
        case "START_SUBMITTING": {
            let newState = { ...state, isSubmitting: true }
            return newState
        }
        case "STOP_SUBMITTING": {
            let newState = { ...state, isSubmitting: false }
            return newState
        }
        default:
            return state
    }
}

const commonDataReducer = (
    state = {
        countries: [],
        states: [],
        cities: [],
        states_copy: [],
        cities_copy: [],
        issueCategories: null,
        issueSubCategories: null,
        subCategories_copy: [],
        boardNames: [],
        courses: [],
        courses_copy: null,
        departments: null,
        examCenters: [],
        bulletinFile: null,
        last_date: null,
        settings: {},
    },
    action
) => {
    switch (action.type) {
        case "GET_STATES": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    countries: action.payload.data,
                    states: [],
                }
                return newState
            } else {
                let newState = { ...state, countries: [], states: [] }
                return newState
            }
        }

        case "GET_INDIAN_STATES": {
            if (action.payload.status === 200) {
                let newState = { ...state, states: action.payload.data }
                return newState
            } else {
                let newState = { ...state, states: [] }
                return newState
            }
        }

        case "COURSE_LAST_DATE": {
            let newState = { ...state, last_date: action.payload }
            return newState
        }

        case "GET_ISSUE_CATEGORIES": {
            if (action.payload.status === 200) {
                let mainCats = action.payload.data.filter(function (category) {
                    return category.type === "1"
                })

                let subCats = action.payload.data.filter(function (category) {
                    return category.main_category !== null
                })

                let newState = {
                    ...state,
                    issueCategories: mainCats,
                    subCategories_copy: subCats,
                }
                return newState
            } else {
                let newState = { ...state, issueCategories: null }
                return newState
            }
        }
        case "GET_COURSES": {
            if (action.payload.status === 200) {
                let newState = { ...state, courses: action.payload.data }
                return newState
            } else {
                let newState = { ...state, courses: [] }
                return newState
            }
        }
        case "FETCH_SETTINGS": {
            if (action.payload.status === 200) {
                let newState = { ...state, settings: action.payload.data }
                return newState
            } else {
                let newState = { ...state, settings: {} }
                return newState
            }
        }
        case "FILTER_COURSES": {
            let newState = { ...state, courses_copy: action.payload }
            return newState
        }
        case "GET_DEPARTMENTS": {
            if (action.payload.status === 200) {
                let newState = { ...state, departments: action.payload.data }
                return newState
            } else {
                let newState = { ...state, departments: null }
                return newState
            }
        }
        case "FETCH_EXAM_CENTERS": {
            if (action.payload.status === 200) {
                let newState = { ...state, examCenters: action.payload.data }
                return newState
            } else {
                let newState = { ...state, examCenters: [] }
                return newState
            }
        }
        case "SET_ISSUE_SUB_CATEGORIES": {
            let newState = { ...state, issueSubCategories: action.payload }
            return newState
        }
        case "PRESENT_STATES": {
            let newState = { ...state, states: action.payload }
            return newState
        }
        case "PRESENT_CITIES": {
            let newState = { ...state, cities: action.payload }
            return newState
        }
        case "PERMANENT_STATES": {
            let newState = { ...state, states_copy: action.payload }
            return newState
        }
        case "PERMANENT_CITIES": {
            let newState = { ...state, cities_copy: action.payload }
            return newState
        }
        case "GET_PRESENT_CITIES": {
            if (action.payload.status === 200) {
                let newState = { ...state, cities: action.payload.data }
                return newState
            } else {
                let newState = { ...state, cities: [] }
                return newState
            }
        }
        case "GET_PERMANENT_CITIES": {
            if (action.payload.status === 200) {
                let newState = { ...state, cities_copy: action.payload.data }
                return newState
            } else {
                let newState = { ...state, cities_copy: [] }
                return newState
            }
        }
        case "SUBMIT_BULLETIN_FORM": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    bulletinFile: action.payload.data.file,
                }
                return newState
            } else {
                let newState = { ...state, bulletinFile: null }
                return newState
            }
        }
        case "FETCH_BOARD_NAMES": {
            if (action.payload.status === 200) {
                let newState = { ...state, boardNames: action.payload.data }
                return newState
            } else {
                let newState = { ...state, boardNames: null }
                return newState
            }
        }
        case "RESET_BULLETIN": {
            let newState = { ...state, bulletinFile: null }
            return newState
        }
        default:
            return state
    }
}

const userReducer = (
    state = {
        login: false,
        loginLoading: true,
        forgotPassword: false,
        resetOTP: false,
        resetMobile: null,
        resetResend: false,
        resetVerify: false,
        newPassword: false,
        user: {},
        applications: null,
        issues: null,
    },
    action
) => {
    switch (action.type) {
        case "USER_LOGIN": {
            if (action.payload.status === 200) {
                let newState = { ...state, login: true }
                return newState
            } else {
                let newState = { ...state, login: false }
                return newState
            }
        }
        case "CHECK_USER": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    login: true,
                    loginLoading: false,
                    user: action.payload.data,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    login: false,
                    loginLoading: false,
                    user: {},
                }
                return newState
            }
        }
        case "LOGOUT_USER": {
            if (action.payload.status === 200) {
                let newState = { ...state, login: false, loginLoading: false }
                return newState
            } else {
                let newState = { ...state, login: false, loginLoading: false }
                return newState
            }
        }
        case "FETCH_MY_APLICATIONS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    applications: action.payload.data.applications,
                }
                return newState
            } else {
                let newState = { ...state, applications: null }
                return newState
            }
        }
        case "GET_MY_ISSUES": {
            if (action.payload.status === 200) {
                let newState = { ...state, issues: action.payload.data.issues }
                return newState
            } else {
                let newState = { ...state, issues: null }
                return newState
            }
        }
        case "FORGOT_PASSWORD": {
            let newState = { ...state, forgotPassword: action.payload }
            return newState
        }
        case "SEND_RESET_OTP": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    resetOTP: true,
                    resetMobile: action.payload.data.mobile,
                }
                return newState
            } else {
                let newState = { ...state, resetOTP: false, resetMobile: null }
                return newState
            }
        }
        case "RESEND_RESET_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, resetResend: true }
                return newState
            } else {
                let newState = { ...state, resetResend: false }
                return newState
            }
        }
        case "CHANGE_PASSWORD": {
            if (action.payload.status === 200) {
                let newState = { ...state, newPassword: true }
                return newState
            } else {
                let newState = { ...state, newPassword: false }
                return newState
            }
        }
        case "VERIFY_RESET_OTP": {
            if (action.payload.status === 200) {
                let newState = { ...state, resetVerify: true }
                return newState
            } else {
                let newState = { ...state, resetVerify: false }
                return newState
            }
        }
        default:
            return state
    }
}

const applyReducer = (
    state = {
        status: false,
        applicationInfo: {},
        editId: null,
        stepOne: {},
        stepTwo: {},
        stepThree: {},
        qualifications: [],
        paymentData: {},
        admissionPaymentData: {},
        payment: false,
        payment_success: false,
        payment_error: false,
        payment_receipt: {},
        admission_payment_receipt: {},
        admit_card: {},
        merit_docs: {},
    },
    action
) => {
    switch (action.type) {
        case "RAZORPAY_PAYMENT_SUCCESSFULL": {
            let newState = { ...state, payment_success: true }
            return newState
        }
        case "RAZORPAY_PAYMENT_FAILED": {
            let newState = { ...state, payment_error: true }
            return newState
        }
        case "GET_PAYMENT_RECEIPT": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    payment_receipt: action.payload.data,
                }
                return newState
            } else {
                let newState = { ...state, payment_receipt: {} }
                return newState
            }
        }
        case "GET_ADMISSION_PAYMENT_RECEIPT": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    admission_payment_receipt: action.payload.data,
                }
                return newState
            } else {
                let newState = { ...state, admission_payment_receipt: {} }
                return newState
            }
        }
        case "GET_MERIT_DOCS": {
            if (action.payload.status === 200) {
                let newState = { ...state, merit_docs: action.payload.data }
                return newState
            } else {
                let newState = { ...state, merit_docs: {} }
                return newState
            }
        }
        case "UPLOAD_MERIT_DOCS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    merit_docs: action.payload.data,
                    status: true,
                }
                return newState
            } else {
                let newState = { ...state, merit_docs: {}, status: false }
                return newState
            }
        }
        case "GET_ADMIT_CARD": {
            if (action.payload.status === 200) {
                let newState = { ...state, admit_card: action.payload.data }
                return newState
            } else {
                let newState = { ...state, admit_card: {} }
                return newState
            }
        }
        case "START_APPLY": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    applicationInfo: action.payload.data.application,
                    status: true,
                }
                return newState
            } else {
                let newState = { ...state, applicationInfo: {}, status: false }
                return newState
            }
        }
        case "GET_PERSONAL_DETAILS": {
            if (action.payload.status === 200) {
                let newState = { ...state, stepOne: action.payload.data }
                return newState
            } else {
                let newState = { ...state, stepOne: {} }
                return newState
            }
        }
        case "GET_BASIC_DETAILS": {
            if (action.payload.status === 200) {
                let newState = { ...state, stepTwo: action.payload.data }
                return newState
            } else {
                let newState = { ...state, stepTwo: {} }
                return newState
            }
        }
        case "NO_FEE_COMPLETE_APPLICATION": {
            if (action.payload.status === 200) {
                let newState = { ...state, payment: true, payment_error: false }
                return newState
            } else {
                let newState = { ...state, payment: false, payment_error: true }
                return newState
            }
        }
        case "GET_PAYMENT_DETAILS": {
            if (action.payload.status === 200) {
                let newState = { ...state, paymentData: action.payload.data }
                return newState
            } else {
                let newState = { ...state, paymentData: {} }
                return newState
            }
        }
        case "GET_ADMISSION_PAYMENT_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    admissionPaymentData: action.payload.data,
                }
                return newState
            } else {
                let newState = { ...state, admissionPaymentData: {} }
                return newState
            }
        }
        case "GET_EDUCATION_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    stepThree: action.payload.data,
                    qualifications: action.payload.data.qualifications,
                }
                return newState
            } else {
                let newState = { ...state, stepThree: {}, qualifications: [] }
                return newState
            }
        }
        case "SUBMIT_EDU_PAGE_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    stepThree: action.payload.data,
                    qualifications: action.payload.data.qualifications,
                    status: true,
                }
                return newState
            } else {
                return state
            }
        }
        case "SUBMIT_PERSONAL_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    stepOne: action.payload.data.application,
                    status: true,
                }
                return newState
            } else {
                return state
            }
        }
        case "SUBMIT_BASIC_DETAILS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    stepTwo: action.payload.data.application,
                    status: true,
                }
                return newState
            } else {
                return state
            }
        }
        case "SUBMIT_EDUCATION_QUALIFICATION": {
            if (action.payload.status === 200) {
                let newState = { ...state, qualifications: action.payload.data }
                return newState
            } else {
                let newState = { ...state, qualifications: [] }
                return newState
            }
        }
        case "REMOVE_EDUCATION_QUALIFICATION": {
            if (action.payload.status === 200) {
                let newState = { ...state, qualifications: action.payload.data }
                return newState
            } else {
                return state
            }
        }
        case "EDIT_QUALIFICATION": {
            let newState = { ...state, editId: action.payload }
            return newState
        }
        case "EDIT_EDUCATION_QUALIFICATION": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    qualifications: action.payload.data,
                    editId: null,
                }
                return newState
            } else {
                return state
            }
        }
        case "FINAL_SUBMIT_APPLY": {
            if (action.payload.status === 200) {
                let newState = { ...state, status: true }
                return newState
            } else {
                let newState = { ...state, status: false }
                return newState
            }
        }
        default:
            return state
    }
}

const appDataReducer = (
    state = {
        notices: null,
        links: null,
        courseDetails: {},
        faq: [],
        questionPapers: [],
        syllabuses: [],
        singleLinks: {},
    },
    action
) => {
    switch (action.type) {
        case "FETCH_NOTICES_LINKS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    notices: action.payload.data.notices,
                }
                return newState
            } else {
                let newState = { ...state, notices: null }
                return newState
            }
        }
        case "FETCH_COURSE_DETAILS": {
            if (action.payload.status === 200) {
                let newState = { ...state, courseDetails: action.payload.data }
                return newState
            } else {
                let newState = { ...state, courseDetails: {} }
                return newState
            }
        }
        case "FIND_NOTICES": {
            if (action.payload.status === 200) {
                let newState = { ...state, notices: action.payload.data }
                return newState
            } else {
                let newState = { ...state, notices: null }
                return newState
            }
        }
        case "FETCH_FAQ": {
            if (action.payload.status === 200) {
                let newState = { ...state, faq: action.payload.data.faqs }
                return newState
            } else {
                let newState = { ...state, faq: [] }
                return newState
            }
        }
        case "FETCH_QUESTION_PAPERS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    questionPapers: action.payload.data.questionpapers,
                }
                return newState
            } else {
                let newState = { ...state, questionPapers: null }
                return newState
            }
        }
        case "FETCH_SYLLABUSES": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    syllabuses: action.payload.data.syllabuses,
                }
                return newState
            } else {
                let newState = { ...state, syllabuses: null }
                return newState
            }
        }
        case "FIND_QUESTION_PAPERS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    questionPapers: action.payload.data.questionpapers,
                }
                return newState
            } else {
                let newState = { ...state, questionPapers: null }
                return newState
            }
        }
        case "FIND_SYLLABUSES": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    syllabuses: action.payload.data.syllabuses,
                }
                return newState
            } else {
                let newState = { ...state, syllabuses: null }
                return newState
            }
        }
        case "FETCH_SINGLE_LINKS": {
            if (action.payload.status === 200) {
                let newState = { ...state, singleLinks: action.payload.data }
                return newState
            } else {
                let newState = { ...state, singleLinks: null }
                return newState
            }
        }
        default:
            return state
    }
}

export default combineReducers({
    modal: modalReducer,
    bannerForm: bannerFormReducer,
    commonFunctions: commonFunctionsReducer,
    commonData: commonDataReducer,
    user: userReducer,
    apply: applyReducer,
    appData: appDataReducer,
})
