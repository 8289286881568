import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Nss extends Component {

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={value === selected} />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.map(option => {
            return (
                <option value={option.id}>{option.name}</option>
            )
        });
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        const selectData = [{ id: 1, name: 'More than or equal to 240 hours'}, { id: 2, name: 'More than or equal to 120-239 hours'}, { id: 3, name: 'Less than 120 hours'}];

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">
                <div className="mb-4">
                    <div>
                        <label>Are you NSS Volunteer? </label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="nss.nss" text="Yes" value="Y" selected={props.values.nss.nss}></Field>
                            <Field component={this.myRadioInput} name="nss.nss" text="No" value="N" selected={props.values.nss.nss}></Field>
                        </div>
                        {getIn(props.errors, 'nss.nss') && getIn(props.touched, 'nss.nss') && <ErrorMsg msg={getIn(props.errors, 'nss.nss')} />}
                    </div>
                </div>

                {
                    props.values.nss.nss === 'Y' ? 
                    (
                        <React.Fragment>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                
                                <div className="form-input">
                                    <Field component={this.myTextInput} name="nss.certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                    {getIn(props.errors, 'nss.certificate') && getIn(props.touched, 'nss.certificate') && <ErrorMsg msg={getIn(props.errors, 'nss.certificate')} />}
                                </div>
                                <div className="form-input">
                                <div className="w-full">
                                    <label>Date Of Issue</label>
                                    <div className="text-input mt-2">
                                        <DatePicker
                                            selected={props.values.nss.date_of_issue}
                                            onChange={date => props.setFieldValue("nss.date_of_issue", date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd-MM-yyyy"
                                            className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                            placeholderText="Click to select a date"
                                        />
                                        {getIn(props.errors, 'nss.date_of_issue') && getIn(props.touched, 'nss.date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'nss.date_of_issue')} />}
                                    </div>
                                </div>
                            </div>
                                <div className="form-input">
                                    <Field component={this.myTextInput} name="nss.authority" placeholder="Issuing Authority " type="text" readonly={false}></Field>
                                    {getIn(props.errors, 'nss.authority') && getIn(props.touched, 'nss.authority') && <ErrorMsg msg={getIn(props.errors, 'nss.authority')} />}
                                </div>
                                <div className="form-input">
                                    <Field component={this.mySelectInput} name="nss.category" label="Hours of social work done" options={selectData}></Field>
                                    {getIn(props.errors, 'nss.category') && getIn(props.touched, 'nss.category') && <ErrorMsg msg={getIn(props.errors, 'nss.category')} />}
                                </div>
                                
                            </div>

                            <div className="mb-4">
                                <label>NSS NIC (National Integration Camp) Attended </label>
                                <div className="grid grid-cols-3 gap-4 mt-2">
                                    <Field component={this.myRadioInput} name="nss.nic" text="Yes" value="Y" selected={props.values.nss.nic}></Field>
                                    <Field component={this.myRadioInput} name="nss.nic" text="No" value="N" selected={props.values.nss.nic}></Field>
                                </div>
                                {getIn(props.errors, 'nss.nic') && getIn(props.touched, 'nss.nic') && <ErrorMsg msg={getIn(props.errors, 'nss.nic')} />}
                            </div>

                            {
                                props.values.nss.nic === 'Y' ? 
                                (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="nss.nic_certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'nss.nic_certificate') && getIn(props.touched, 'nss.nic_certificate') && <ErrorMsg msg={getIn(props.errors, 'nss.nic_certificate')} />}
                                        </div>
                                        <div className="form-input">
                                            <div className="w-full">
                                                <label>Date Of Issue</label>
                                                <div className="text-input mt-2">
                                                    <DatePicker
                                                        selected={props.values.nss.nic_date_of_issue}
                                                        onChange={date => props.setFieldValue("nss.nic_date_of_issue", date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                        placeholderText="Click to select a date"
                                                    />
                                                    {getIn(props.errors, 'nss.nic_date_of_issue') && getIn(props.touched, 'nss.nic_date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'nss.nic_date_of_issue')} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="nss.nic_authority" placeholder="Issuing Authority " type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'nss.nic_authority') && getIn(props.touched, 'nss.nic_authority') && <ErrorMsg msg={getIn(props.errors, 'nss.nic_authority')} />}
                                        </div>
                                    </div>
                                ) : null
                            }

                            <div className="mb-4">
                                <label>RDC (Republic Day Camp) Attended  </label>
                                <div className="grid grid-cols-3 gap-4 mt-2">
                                    <Field component={this.myRadioInput} name="nss.rdc" text="Yes" value="Y" selected={props.values.nss.rdc}></Field>
                                    <Field component={this.myRadioInput} name="nss.rdc" text="No" value="N" selected={props.values.nss.rdc}></Field>
                                </div>
                                {getIn(props.errors, 'nss.rdc') && getIn(props.touched, 'nss.rdc') && <ErrorMsg msg={getIn(props.errors, 'nss.rdc')} />}
                            </div>

                            {
                                props.values.nss.rdc === 'Y' ? 
                                (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="nss.rdc_certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'nss.rdc_certificate') && getIn(props.touched, 'nss.rdc_certificate') && <ErrorMsg msg={getIn(props.errors, 'nss.rdc_certificate')} />}
                                        </div>
                                        <div className="form-input">
                                            <div className="w-full">
                                                <label>Date Of Issue</label>
                                                <div className="text-input mt-2">
                                                    <DatePicker
                                                        selected={props.values.nss.rdc_date_of_issue}
                                                        onChange={date => props.setFieldValue("nss.rdc_date_of_issue", date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                        placeholderText="Click to select a date"
                                                    />
                                                    {getIn(props.errors, 'nss.rdc_date_of_issue') && getIn(props.touched, 'nss.rdc_date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'nss.rdc_date_of_issue')} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="nss.rdc_authority" placeholder="Issuing Authority " type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'nss.rdc_authority') && getIn(props.touched, 'nss.rdc_authority') && <ErrorMsg msg={getIn(props.errors, 'nss.rdc_authority')} />}
                                        </div>
                                    </div>
                                ) : null
                            }

                            <div className="mb-4">
                                <label>Special camp Attended  </label>
                                <div className="grid grid-cols-3 gap-4 mt-2">
                                    <Field component={this.myRadioInput} name="nss.camp" text="Yes" value="Y" selected={props.values.nss.camp}></Field>
                                    <Field component={this.myRadioInput} name="nss.camp" text="No" value="N" selected={props.values.nss.camp}></Field>
                                    
                                </div>
                                {getIn(props.errors, 'nss.camp') && getIn(props.touched, 'nss.camp') && <ErrorMsg msg={getIn(props.errors, 'nss.camp')} />}
                            </div>

                            {
                                props.values.nss.camp === 'Y' ? 
                                (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="nss.camp_certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'nss.camp_certificate') && getIn(props.touched, 'nss.camp_certificate') && <ErrorMsg msg={getIn(props.errors, 'nss.camp_certificate')} />}
                                        </div>
                                        <div className="form-input">
                                            <div className="w-full">
                                                <label>Date Of Issue</label>
                                                <div className="text-input mt-2">
                                                    <DatePicker
                                                        selected={props.values.nss.camp_date_of_issue}
                                                        onChange={date => props.setFieldValue("nss.camp_date_of_issue", date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                        placeholderText="Click to select a date"
                                                    />
                                                    {getIn(props.errors, 'nss.camp_date_of_issue') && getIn(props.touched, 'nss.camp_date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'nss.camp_date_of_issue')} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="nss.camp_authority" placeholder="Issuing Authority " type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'nss.camp_authority') && getIn(props.touched, 'nss.camp_authority') && <ErrorMsg msg={getIn(props.errors, 'nss.camp_authority')} />}
                                        </div>
                                    </div>
                                ) : null
                            }

                        </React.Fragment>
                    ) : null
                }
                
            </div>
        )
    }
}

export default Nss;