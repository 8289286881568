import React, { Component } from "react"
import { connect } from "react-redux"
import ImportantLinkItem from "./ImportantLinkItem"

import { fetchSingleLinks, showModal } from "../../../../../actions"
import { baseURL2 } from "../../../includes/jsFunctions"
import PageModal from "../../../includes/modal/PageModal"
import BulletinForm from "../../../navbar/BulletinForm"

class ImportantLinks extends Component {
    componentDidMount() {
        this.props.fetchSingleLinks()
    }

    showModal = (name) => {
        this.props.showModal(name)
    }

    render() {
        const { important_dates, general_instructions, omr_instruction } =
            this.props.appData.singleLinks

        const important_date_file =
            important_dates && important_dates.filename
                ? important_dates.filename
                : null
        const general_instructions_file =
            general_instructions && general_instructions.filename
                ? general_instructions.filename
                : null
        const omr_instruction_file =
            omr_instruction && omr_instruction.filename
                ? omr_instruction.filename
                : null

        return (
            <React.Fragment>
                <div className="important-links-items">
                    {/* <ImportantLinkItem image="/icon/cal.png" type="link" slug={`${baseURL2}${important_date_file}`} title="Important Dates" />
                    <ImportantLinkItem image="/icon/apply.png" isClicked={this.showModal} type="modal" title="Admission Bulletin" />
                    <ImportantLinkItem image="/icon/test.png" slug="/syllabuses" title="RGUCET Syllabus" />
                    <ImportantLinkItem image="/icon/book.png" type="link" slug={`${baseURL2}${general_instructions_file}`} title="General Instruction" />
                    <ImportantLinkItem image="/icon/elearning.png" slug="/question-papers" title="Sample Question Papers" />
                    <ImportantLinkItem image="/icon/book.png" type="link" slug={`${baseURL2}${omr_instruction_file}`} title="OMR Instruction" /> */}
                </div>

                {this.props.modal.modalOpen &&
                this.props.modal.modalName === "bulletinModal2" ? (
                    <PageModal>
                        <BulletinForm />
                    </PageModal>
                ) : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appData: state.appData,
        modal: state.modal,
    }
}

export default connect(mapStateToProps, { showModal, fetchSingleLinks })(
    ImportantLinks
)
