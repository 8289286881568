import * as Yup from 'yup';

const validate = Yup.object().shape({

    community: Yup.object().shape({
        name: Yup.string()
        .required("Please select your community")
        .nullable(),

        caste: Yup.string()
        .when('name', {
            is: name => parseInt(name) > 1,
            then: Yup.string()
              .required('Please enter your caste'),otherwise: Yup.string().notRequired()
        }).nullable(),

        certificate: Yup.string()
        .when('name', {
            is: name => parseInt(name) > 1,
            then: Yup.string()
              .required('Please enter certificate no'),otherwise: Yup.string().notRequired()
        }).nullable(),

        date_of_issue: Yup.string()
        .when('name', {
            is: name => parseInt(name) > 1,
            then: Yup.string()
              .required('Please select date of issue'),otherwise: Yup.string().notRequired()
        }),

        authority: Yup.string()
        .when('name', {
            is: name => parseInt(name) > 1,
            then: Yup.string()
              .required('Please enter issuing authority').nullable(),
              otherwise: Yup.string().notRequired()
        }).nullable(),

        ncl_certificate_no: Yup.string()
        .when('name', {
            is: name => parseInt(name) === 2,
            then: Yup.string()
              .required('Please enter NCL certificate no'),otherwise: Yup.string().notRequired()
        }).nullable(),

        ncl_date_of_issue: Yup.string()
        .when('name', {
            is: name => parseInt(name) === 2,
            then: Yup.string()
              .required('Please enter NCL date of issue'),otherwise: Yup.string().notRequired()
        }),

        ncl_authority: Yup.string()
        .when('name', {
            is: name => parseInt(name) === 2,
            then: Yup.string()
              .required('Please enter NCL issuing authority')
              ,otherwise: Yup.string().notRequired()
        }).nullable(),

        ews: Yup.string()
        .when('name', {
            is: name => parseInt(name) === 1,
            then: Yup.string()
              .required('Please select if you belong to EWS category?'),otherwise: Yup.string().notRequired()
        }),

        ews_certificate: Yup.string()
        .when(['ews', 'name'], {
            is: (ews, name) => ews === 'Y' && parseInt(name) === 1,
            then: Yup.string()
              .required('Please enter certificate number'),
              otherwise: Yup.string().notRequired()
        }),

        ews_date_of_issue: Yup.string()
        .when(['ews', 'name'], {
            is: (ews, name) => ews === 'Y' && parseInt(name) === 1,
            then: Yup.string()
              .required('Please select date of issue'),
              otherwise: Yup.string().notRequired()
        }),

        ews_authority: Yup.string()
        .when(['ews', 'name'], {
            is: (ews, name) => ews === 'Y' && parseInt(name) === 1,
            then: Yup.string()
              .required('Please enter issuing authority'),
              otherwise: Yup.string().notRequired()
        }),

    }),

    serviceman: Yup.object().shape({
        serviceman: Yup.string()
        .required("Please select if you a Ward of Ex-Serviceman/Serviceman?")
        .nullable(),

        certificate: Yup.string()
        .when('serviceman', {
            is: serviceman => serviceman === 'Y',
            then: Yup.string()
              .required('Please enter certificate number'),
              otherwise: Yup.string().notRequired()
        }),

        date_of_issue: Yup.string()
        .when('serviceman', {
            is: serviceman => serviceman === 'Y',
            then: Yup.string()
              .required('Please enter date of issue'),
              otherwise: Yup.string().notRequired()
        }),

        authority: Yup.string()
        .when('serviceman', {
            is: serviceman => serviceman === 'Y',
            then: Yup.string()
              .required('Please enter issueing authority'),
              otherwise: Yup.string().notRequired()
        }),

        designation: Yup.string()
        .when('serviceman', {
            is: serviceman => serviceman === 'Y',
            then: Yup.string()
              .required('Please enter designation'),
              otherwise: Yup.string().notRequired()
        }),

    }),

    disability: Yup.object().shape({
        disable: Yup.string()
        .required("Please select if you are a Differently-abled person?")
        .nullable(),

        certificate: Yup.string()
        .when('disable', {
            is: disable => disable === 'Y',
            then: Yup.string()
              .required('Please enter certificate number'),
              otherwise: Yup.string().notRequired()
        }),

        date_of_issue: Yup.string()
        .when('disable', {
            is: disable => disable === 'Y',
            then: Yup.string()
              .required('Please enter date of issue'),
              otherwise: Yup.string().notRequired()
        }),

        percentage: Yup.string()
        .when('disable', {
            is: disable => disable === 'Y',
            then: Yup.string()
              .required('Please enterpercentage of disability'),
              otherwise: Yup.string().notRequired()
        }),

        description: Yup.string()
        .when('disable', {
            is: disable => disable === 'Y',
            then: Yup.string()
              .required('Please enter description of disability'),
              otherwise: Yup.string().notRequired()
        }),

    }),

    supernumerary: Yup.object().shape({
        supernumerary: Yup.string()
        .required("Please select if you are a supernumerary candidate?")
        .nullable(),

        designation: Yup.string()
        .when('supernumerary', {
            is: supernumerary => supernumerary === 'Y',
            then: Yup.string()
              .required('Please enter designation'),
              otherwise: Yup.string().notRequired()
        }),

        department: Yup.string()
        .when('supernumerary', {
            is: supernumerary => supernumerary === 'Y',
            then: Yup.string()
              .required('Please enter department'),
              otherwise: Yup.string().notRequired()
        }),

        relation: Yup.string()
        .when('supernumerary', {
            is: supernumerary => supernumerary === 'Y',
            then: Yup.string()
              .required('Please enter your relation'),
              otherwise: Yup.string().notRequired()
        }),

        certificate: Yup.string()
        .when('supernumerary', {
            is: supernumerary => supernumerary === 'Y',
            then: Yup.string()
              .required('Please enter certificate number'),
              otherwise: Yup.string().notRequired()
        }),

        date_of_issue: Yup.string()
        .when('supernumerary', {
            is: supernumerary => supernumerary === 'Y',
            then: Yup.string()
              .required('Please enter date of issue'),
              otherwise: Yup.string().notRequired()
        }),

        authority: Yup.string()
        .when('supernumerary', {
            is: supernumerary => supernumerary === 'Y',
            then: Yup.string()
              .required('Please enter issuing authority'),
              otherwise: Yup.string().notRequired()
        }),

    }),

    sports: Yup.object().shape({
        quota: Yup.string()
        .required("Please select if you are applying under Sports Quota")
        .nullable(),

        name: Yup.string()
        .when('quota', {
            is: quota => quota === 'Y',
            then: Yup.string()
              .required('Please enter name of sport'),
              otherwise: Yup.string().notRequired()
        }),

        level: Yup.string()
        .when('quota', {
            is: quota => quota === 'Y',
            then: Yup.string()
              .required('Please select level of the participation'),
              otherwise: Yup.string().notRequired()
        }),

        certificate: Yup.string()
        .when('quota', {
            is: quota => quota === 'Y',
            then: Yup.string()
              .required('Please enter certificate number'),
              otherwise: Yup.string().notRequired()
        }),

        date_of_issue: Yup.string()
        .when('quota', {
            is: quota => quota === 'Y',
            then: Yup.string()
              .required('Please enter date of issue').nullable(),
              otherwise: Yup.string().notRequired()
        }),

        authority: Yup.string()
        .when('quota', {
            is: quota => quota === 'Y',
            then: Yup.string()
              .required('Please enter issuing authority'),
              otherwise: Yup.string().notRequired()
        }),

    }),

    kashmiri: Yup.object().shape({
      kashmiri: Yup.string()
      .required("Please select if you are a Kashmiri Migrant?")
      .nullable(),

      name: Yup.string()
      .when('kashmiri', {
          is: kashmiri => kashmiri === 'Y',
          then: Yup.string()
            .required('Please enter name of the Person'),
            otherwise: Yup.string().notRequired()
      }),

      relationship: Yup.string()
      .when('kashmiri', {
          is: kashmiri => kashmiri === 'Y',
          then: Yup.string()
            .required('Please select relationship with the person'),
            otherwise: Yup.string().notRequired()
      }),

      certificate: Yup.string()
      .when('kashmiri', {
          is: kashmiri => kashmiri === 'Y',
          then: Yup.string()
            .required('Please enter certificate number'),
            otherwise: Yup.string().notRequired()
      }),

      date_of_issue: Yup.string()
      .when('kashmiri', {
          is: kashmiri => kashmiri === 'Y',
          then: Yup.string()
            .required('Please select date of issue').nullable(),
            otherwise: Yup.string().notRequired()
      }),

      authority: Yup.string()
      .when('kashmiri', {
          is: kashmiri => kashmiri === 'Y',
          then: Yup.string()
            .required('Please enter issuing authority'),
            otherwise: Yup.string().notRequired()
      }),

    }),

    ncc: Yup.object().shape({
      ncc: Yup.string()
      .required("Please select if you are a B/C NCC certificate holder?")
      .nullable(),

      category: Yup.string()
      .when('ncc', {
          is: ncc => ncc === 'Y',
          then: Yup.string()
            .required('Please select certificate category'),
            otherwise: Yup.string().notRequired()
      }),

      certificate: Yup.string()
      .when('ncc', {
          is: ncc => ncc === 'Y',
          then: Yup.string()
            .required('Please enter certificate number'),
            otherwise: Yup.string().notRequired()
      }),

      date_of_issue: Yup.string()
      .when('ncc', {
          is: ncc => ncc === 'Y',
          then: Yup.string()
            .required('Please select date of issue').nullable(),
            otherwise: Yup.string().notRequired()
      }),

      authority: Yup.string()
      .when('ncc', {
          is: ncc => ncc === 'Y',
          then: Yup.string()
            .required('Please enter issuing authority'),
            otherwise: Yup.string().notRequired()
      }),

    }),

    nss: Yup.object().shape({
      nss: Yup.string()
      .required("Please select if you are a NSS volunteer?")
      .nullable(),

      certificate: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please enter certificate number'),
            otherwise: Yup.string().notRequired()
      }),

      date_of_issue: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please select date of issue').nullable(),
            otherwise: Yup.string().notRequired()
      }),

      authority: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please enter issuing authority'),
            otherwise: Yup.string().notRequired()
      }),

      category: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please select hours of social work done'),
            otherwise: Yup.string().notRequired()
      }),

      nic: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please select if you are a NIC (National Integration Camp) attended?'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

      nic_certificate: Yup.string()
      .when(['nic', 'nss'], {
          is: (nic, nss) => nic === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please enter certificate number'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

      nic_date_of_issue: Yup.string()
      .when(['nic', 'nss'], {
        is: (nic, nss) => nic === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please select date of issue'),
            otherwise: Yup.string().notRequired()
      }),

      nic_authority: Yup.string()
      .when(['nic', 'nss'], {
        is: (nic, nss) => nic === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please enter issuing authority'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

      rdc: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please select if you are a RDC (Republic Day Camp) attended?').nullable(),
            otherwise: Yup.string().notRequired()
      }),

      rdc_certificate: Yup.string()
      .when(['rdc', 'nss'], {
        is: (rdc, nss) => rdc === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please enter certificate number'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

      rdc_date_of_issue: Yup.string()
      .when(['rdc', 'nss'], {
        is: (rdc, nss) => rdc === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please select date of issue'),
            otherwise: Yup.string().notRequired()
      }),

      rdc_authority: Yup.string()
      .when(['rdc', 'nss'], {
        is: (rdc, nss) => rdc === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please enter issuing authority'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

      camp: Yup.string()
      .when('nss', {
          is: nss => nss === 'Y',
          then: Yup.string()
            .required('Please select if you have attended Special camp?').nullable(),
            otherwise: Yup.string().notRequired()
      }),

      camp_certificate: Yup.string()
      .when(['camp', 'nss'], {
        is: (camp, nss) => camp === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please enter certificate number'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

      camp_date_of_issue: Yup.string()
      .when(['camp', 'nss'], {
        is: (camp, nss) => camp === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please select date of issue'),
            otherwise: Yup.string().notRequired()
      }),

      camp_authority: Yup.string()
      .when(['camp', 'nss'], {
        is: (camp, nss) => camp === 'Y' && nss === 'Y',
          then: Yup.string()
            .required('Please enter issuing authority'),
            otherwise: Yup.string().notRequired()
      }).nullable(),

    })

});

export default validate;