import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik } from "formik"

//import SelectInput from '../includes/inputs/SelectInput';
import ErrorMsg from "../includes/form-inputs/ErrorMsg"
import { courseFilter, startApply, checkLastDate } from "../../../actions"
import LoadingBtn from "../includes/btn/LoadingBtn"
import { staticURI } from "../includes/jsFunctions"

class DashboardBanner extends Component {
    submitFrom = (values, actions) => {
        this.props.startApply(values, actions)
    }

    mySelectInput = ({ field, name, options }) => {
        const selectOptions =
            options && options.length > 0
                ? options.map((option) => {
                      return (
                          <option value={option.id} key={option.id}>
                              {option.title}
                          </option>
                      )
                  })
                : null

        return (
            <div className="w-full">
                <div className="select-input">
                    <select
                        name={name}
                        className={`block w-full py-2 px-3 border border-gray-300 rounded`}
                        {...field}
                    >
                        <option value="">Please Select</option>
                        {selectOptions}
                    </select>
                </div>
            </div>
        )
    }

    validate = (values) => {
        let errors = {}
        if (!values.department) {
            errors.department = "Please select department"
        } else {
            this.filterValues(this.props.commonData.courses, values.department)
        }

        if (!values.course) {
            errors.course = "Please select course"
        } else {
            this.checkLastDate(this.props.commonData.courses, values.course)
        }

        return errors
    }

    filterValues = (options, department) => {
        let filteredCourses = options.filter(function (course) {
            return course.department_id === department
        })
        this.props.courseFilter(filteredCourses)
    }

    checkLastDate = (options, course) => {
        const selectedCourse = options.find(
            (item) => item.id === parseInt(course)
        )
        this.props.checkLastDate(selectedCourse)
    }

    render() {
        const { departments, courses_copy, last_date } = this.props.commonData
        const { isSubmitting } = this.props.commonFunctions
        const { status, applicationInfo } = this.props.apply
        const { application } = this.props.settings

        if (status) {
            window.location.href = `${staticURI}/apply/personal-details/${applicationInfo.application_id}`
        }

        let apply_open = true

        if (last_date) {
            let today = new Date()
            let apply_last_date = new Date(last_date)

            apply_last_date.setDate(apply_last_date.getDate() + 1)

            let add_one_day = new Date(
                apply_last_date.getFullYear() +
                    "-" +
                    ("0" + (apply_last_date.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + apply_last_date.getDate()).slice(-2)
            )

            if (today.getTime() > add_one_day.getTime()) {
                apply_open = false
            }
        }

        return (
            <section
                className="dashboard-banner-section"
                style={{ backgroundColor: "#F5FBFF" }}
            >
                <div
                    className="search-bg"
                    style={{
                        backgroundImage: `url(/images/page-banner.png)`,
                        height: 300,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                ></div>
                <div className="dashboard-form-wrapper bg-white w-full md:w-2/5 mx-auto -mt-16 py-8 px-8 md:px-24 shadow-md">
                    {application ? (
                        <Formik
                            initialValues={{ department: "", course: "" }}
                            onSubmit={(values, actions) => {
                                this.submitFrom(values, actions)
                            }}
                            validate={this.validate}
                        >
                            {(props: FormikProps<any>) => (
                                <Form>
                                    <div className="mb-4">
                                        <label>Select Department: </label>
                                        <Field
                                            component={this.mySelectInput}
                                            name="department"
                                            options={departments}
                                        ></Field>
                                        {props.touched.department &&
                                            props.errors.department && (
                                                <ErrorMsg
                                                    msg={
                                                        props.errors.department
                                                    }
                                                />
                                            )}
                                    </div>
                                    <div className="mb-4">
                                        <label>Select Course: </label>
                                        <Field
                                            component={this.mySelectInput}
                                            name="course"
                                            options={courses_copy}
                                        ></Field>
                                        {props.touched.course &&
                                            props.errors.course && (
                                                <ErrorMsg
                                                    msg={props.errors.course}
                                                />
                                            )}
                                    </div>

                                    {last_date && apply_open ? (
                                        <div className="submit-btn-wrap text-center">
                                            <button
                                                type="submit"
                                                className="rgu-btn-light"
                                            >
                                                {isSubmitting ? (
                                                    <LoadingBtn />
                                                ) : (
                                                    `Apply Now`
                                                )}
                                            </button>
                                        </div>
                                    ) : last_date && !apply_open ? (
                                        <div className="submit-btn-wrap text-center">
                                            <span className="text-red-500">
                                                The last date to apply in this
                                                course is over.
                                            </span>
                                            <button
                                                type="button"
                                                className="rgu-btn-light mt-3"
                                                disabled={true}
                                            >
                                                Apply Now
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="submit-btn-wrap text-center">
                                            <button
                                                type="submit"
                                                className="rgu-btn-light"
                                            >
                                                {isSubmitting ? (
                                                    <LoadingBtn />
                                                ) : (
                                                    `Apply Now`
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <h2 className="text-center font-bold text-xl">
                            Congratulations! You have successfully registered.
                            Application will start soon. Please keep visiting.
                        </h2>
                    )}
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
        settings: state.commonData.settings,
    }
}

export default connect(mapStateToProps, {
    courseFilter,
    startApply,
    checkLastDate,
})(DashboardBanner)
